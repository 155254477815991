import React, { Fragment, useEffect, useRef, useState } from "react";

import Header from "../components/Header";
import Headermob from "../components/Headermob";
import Upperheader from "../components/Upperheader";
import Lowerheader from "../components/Lowerheader";
import Footer from "../components/Footer";
import Dashnav from "../components/Dashnav";
import Dashmenu from "../components/Dashmenu";
import ModalInst from "../components/Modal/unverifiedModal";
import UsersTable from "./Table/usersTable";
import { useDispatch, useSelector } from "react-redux";
import { getAllRestaurant, getAllServiceProviders } from "../redux/admin";
import ProductsTable from "./Table/productTable";
import OrderTables from "./Table/orderTables";
import { getAllProductsAdmin } from "../redux/products/getProducts";
import { getAllOrdersAdmin } from "../redux/order";

const Dashboard = () => {
  const [active, setActive] = useState("serviceProviders");
  const [modalShow, setModalShow] = useState(true);

  const sectionRef = useRef(null);

  // User
  const user = JSON.parse(localStorage.getItem("user")).data;


  // Redux
  const dispatch = useDispatch();
  const service = useSelector((state) => state.admin.serviceProviders);
  const restaurant = useSelector((state) => state.admin.restaurants);
  const products = useSelector((state) => state.getProducts.products);
  const orders = useSelector((state) => state.order.orders);

  useEffect(() => {
   
    if (
      active === "serviceProviders" &&
      user.role === "Admin" &&
      !service.length
    ) {
      dispatch(getAllServiceProviders());
    } else if (
      active === "Restaurant" &&
      user.role === "Admin" &&
      !restaurant.length
    ) {
      dispatch(getAllRestaurant());
    } else if (active === "Products") {
      if (user.role === "Admin" && !products.length) {
        dispatch(getAllProductsAdmin());
      } else {
      }
    } else if (active === "Orders") {
      if (user.role === "Admin" && !orders.length) {
        dispatch(getAllOrdersAdmin());
      } else;
    }
  }, [dispatch, active, user, service, restaurant, orders, products]);

  const scrollToSection = (ref) => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  };
  // const dach = ()=>{}

  return (
    <Fragment>
      {user.role !== "Admin" && !user.active && (
        <ModalInst show={modalShow} onHide={() => setModalShow(false)} />
      )}
      <Headermob />
      <Upperheader divClass="bg-green"/>
      <Header />
      <Lowerheader />
      <Dashnav title="Dashboard" />

      <div className="main-div pb-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <Dashmenu userName={user.name} />
            </div>
            <div className="col-lg-8 pt-5 ps-4">
              <div className="col-lg-12 ps-2 pe-2">
                <div className="row">
                  {user.role === "Admin" && (
                    <>
                      <div className="col-lg-3 ps-2 pe-2 mb-3">
                        <div
                          onClick={() => {
                            scrollToSection(sectionRef);
                            setActive("serviceProviders");
                          }}
                          className="cursor-pointer card border-0 pt-4 pb-4 text-center alert-warning align-items-center rounded-10"
                        >
                          <i className="psor mt-n5 feather-hard-drive text-white btn-round-md bg-warning font-xs"></i>
                          <h3 className="fw-700 font-xl text-grey-900 mt-2 ls-3 mb-0">
                            0
                          </h3>
                          <span className="font-xssss ls-0 text-grey-700 fw-600 mt-0">
                            Service Providers
                          </span>
                          <span className="mt-2 text-success font-xsssss fw-700 ls-6">
                            + 20%{" "}
                          </span>
                        </div>
                      </div>

                      <div className="col-lg-3 ps-2 pe-2 mb-3">
                        <div
                          onClick={() => {
                            scrollToSection(sectionRef);
                            setActive("Restaurant");
                          }}
                          className=" cursor-pointer card border-0 pt-4 pb-4 text-center alert-success align-items-center rounded-10"
                        >
                          <i className="psor mt-n5 feather-box text-white btn-round-md bg-success font-xs"></i>
                          <h3 className="fw-700 font-xl text-grey-900 mt-2 ls-3 mb-0">
                            0
                          </h3>
                          <span className="font-xssss ls-0 text-grey-700 fw-600 mt-0">
                            Restaurant
                          </span>
                          <span className="mt-2 text-warning font-xsssss fw-700 ls-6">
                            + 40%{" "}
                          </span>
                        </div>
                      </div>
                    </>
                  )}
                  {user.active ? (
                    <>
                      <div className="col-lg-3 ps-2 pe-2 mb-3">
                        <div
                          onClick={() => {
                            scrollToSection(sectionRef);
                            setActive("Products");
                          }}
                          className=" cursor-pointer card border-0 pt-4 pb-4 text-center alert-info align-items-center rounded-10"
                        >
                          <i className="psor mt-n5 feather-award text-white btn-round-md bg-info font-xs"></i>
                          <h3 className="fw-700 font-xl text-grey-900 mt-2 ls-3 mb-0">
                            0
                          </h3>
                          <span className="font-xssss ls-0 text-grey-700 fw-600 mt-0">
                            Products and Services
                          </span>
                          <span className="mt-2 text-danger font-xsssss fw-700 ls-6">
                            + 44%{" "}
                          </span>
                        </div>
                      </div>
                      <div className="col-lg-3 ps-2 pe-2 mb-3">
                        <div
                          onClick={() => {
                            scrollToSection(sectionRef);
                            setActive("Orders");
                          }}
                          className="cursor-pointer card border-0 pt-4 pb-4 text-center alert-secondary align-items-center rounded-10"
                        >
                          <i className="psor mt-n5 feather-flag text-white btn-round-md bg-secondary font-xs"></i>
                          <h3 className="fw-700 font-xl text-grey-900 mt-2 ls-3 mb-0">
                            0
                          </h3>
                          <span className="font-xssss ls-0 text-grey-700 fw-600 mt-0">
                            Orders
                          </span>
                          <span className="mt-2 text-danger font-xsssss fw-700 ls-6">
                            + 44%{" "}
                          </span>
                        </div>
                      </div>
                    </>
                  ) : null}
                  <div className="col-lg-12 ps-2 pe-2">
                    <div className="card border-0  bg-lightblue rounded-10">
                      <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-6 p-5">
                          <h2 className="text-grey-900 fw-700 ls-0 font-xl lh-3 m-0">
                            Organic Store
                            <br />
                            Green Salad
                          </h2>
                          <p className="text-grey-500 font-xssss mt-2 fw-500">
                            Vivamus adipiscing nisl ut dolor dignissim semper.
                            Nulla luctus malesuada tincidunt.
                          </p>
                          <a
                            href="/dashboarddashboard"
                            className="bg-current text-white rounded-25 btn-cart w-125 d-inline-block text-center font-xsssss p-3 fw-600 ls-6"
                          >
                            ANALYTICS
                          </a>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 p-4">
                          <img
                            src="https://via.placeholder.com/225x130.png"
                            alt="flame"
                            className="w-100 pe-3"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* {user.role === "Admin" && ( */}
            <div className="col-lg-12 ps-2 pe-2" ref={sectionRef}>
              <div className="card border-0 mt-3">
                {active === "serviceProviders" ? (
                  <>
                    {service && service.length > 0 ? (
                      <UsersTable users={service} />
                    ) : (
                      <p>No service providers available.</p>
                    )}
                  </>
                ) : active === "Restaurant" ? (
                  <>
                    {restaurant && restaurant.length > 0 ? (
                      <UsersTable users={restaurant} />
                    ) : (
                      <p>No restaurants available.</p>
                    )}
                  </>
                ) : active === "Products" ? (
                  <>
                    {products && products.length > 0 ? (
                      <ProductsTable products={products} />
                    ) : (
                      <p>No products available.</p>
                    )}
                  </>
                ) : (
                  <>
                    {orders && orders.length > 0 ? (
                      <OrderTables orders={orders} />
                    ) : (
                      <p>No orders available.</p>
                    )}
                  </>
                )}
              </div>
            </div>
            {/* )} */}
          </div>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
};

export default Dashboard;
