import React, { Fragment, useState } from "react";
import Footer from "../components/Footer";
import Headermob from "../components/Headermob";
import Header from "../components/Header";
import Upperheader from "../components/Upperheader";
import Dashnav from "../components/Dashnav";
import Lowerheader from "../components/Lowerheader";
import Dashmenu from "../components/Dashmenu";
import { Button } from "react-bootstrap";
import AddCategory from "../components/addCategory";
import AddSubCategory from "../components/addSubCategory";

import CategoryComponent from "../components/categoryComponent";

const Category = () => {
  // State
  const [render, setRender] = useState("allCategory");

  return (
    <Fragment>
      <Headermob />
      <Upperheader />
      <Header />
      <Lowerheader />
      <Dashnav title="Category" />

      <div className="main-div pb-5">
        <div
          className="container"
          style={{ maxWidth: `${render === "allCategory" ? "1450px" : ""}` }}
        >
          <div className="row">
            <div
              className="col-lg-4"
              style={{ width: `${render === "allCategory" ? "25%" : ""}` }}
            >
              <Dashmenu />
            </div>

            <div
              className="col-lg-8 pt-5 ps-4"
              style={{ width: `${render === "allCategory" ? "75%" : ""}` }}
            >
              <Button
                variant="success"
                className="mt-lg-auto mb-4"
                size="lg"
                onClick={() => {
                  setRender("addCategory");
                }}
              >
                <span className="fs-50 text-black fw-700 m-2">+</span>
                Add Category
              </Button>

              <Button
                variant="success"
                className="mt-lg-auto mb-4 ml-4"
                size="lg"
                onClick={() => {
                  setRender("addSubCategory");
                }}
              >
                <span className="fs-50 text-black fw-700 m-2">+</span>
                Add sub Category
              </Button>

              <Button
                variant="success"
                className="mt-lg-auto mb-4 ml-4 "
                size="lg"
                onClick={() => {
                  setRender("allCategory");
                }}
              >
                <span className="fs-50 text-black fw-700 m-2">+</span>
                All Category
              </Button>

              <div className="col-lg-12 ps-2 pe-2">
                <div className="card border-0  rounded-10">
                  <div className="row">
                    <div className="col-lg-12 col-md-6 col-sm-6 p-5">
                      {render === "addCategory" && <AddCategory />}
                      <div className="h-30"></div>
                      {render === "addSubCategory" && <AddSubCategory />}

                      {render === "allCategory" && (
                        <div className="container">
                          <div className="row">
                            <div className="col-lg-12 pt-5 mt-5"></div>
                            <CategoryComponent adminPage={true} />
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 p-4"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
};

export default Category;
