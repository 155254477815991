import { useField } from "formik";

export const ControlWrapper = ({ name, children }) => {
  const [_, meta] = useField(name);

  return (
    <>
      {children}
      {meta.touched && meta.error && (
        <div className="text-danger font-weight-bold">
          {meta.error.toString()}
        </div>
      )}
    </>
  );
};
