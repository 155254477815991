import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../api";

const initialState = {
  categories: [],
  status: "initial",
  error: "null",
};

export const getCategoryFunc = createAsyncThunk("/get-category", async () => {
  const response = await api.get("/categories");

  return response;
});

export const addCategoryAdmin = createAsyncThunk(
  "/add-category",
  async (body) => {
    const response = await api.post("/categories", body);

    return response;
  }
);

export const deleteCategoryAdmin = createAsyncThunk(
  "/delete-category",
  async (categoryId) => {
    const response = await api.delete(`/categories/${categoryId}`);

    return response;
  }
);

export const getCategory = createSlice({
  name: "category",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(addCategoryAdmin.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(addCategoryAdmin.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.categories = [...state.categories, action.payload.data.data];
      })
      .addCase(addCategoryAdmin.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(getCategoryFunc.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getCategoryFunc.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.categories = action.payload.data.data;
      })
      .addCase(getCategoryFunc.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(deleteCategoryAdmin.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(deleteCategoryAdmin.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.categories = state.categories.filter(
          (category) => category === action.payload.data.data
        );
      })
      .addCase(deleteCategoryAdmin.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default getCategory.reducer;
