import React, { useCallback, useEffect } from "react";
import Slider from "react-slick/lib/slider";
import { useDispatch, useSelector } from "react-redux";
import { getDiscountProducts } from "../redux/products/getProducts";

const discountProductsettings = {
  arrows: true,
  dots: false,
  infinite: false,
  speed: 300,
  slidesToShow: 4,
  centerMode: false,
  responsive: [
    {
      breakpoint: 749,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 0,
      settings: {
        slidesToShow: 2,
      },
    },
  ],
};

const DiscountProducts = () => {
  // Redux
  const dispatch = useDispatch();
  const discountProducts = useSelector(
    (state) => state.getProducts.discountProducts
  );

  const fetchProducts = useCallback(() => {
    if (!discountProducts || discountProducts.length === 0) {
      dispatch(getDiscountProducts()).unwrap().finally();
    }
  }, [dispatch, discountProducts]);

  useEffect(() => {
    fetchProducts();
  }, []);
  // fetchProducts
  return (
    <div className="row">
      {/* <div className="col-lg-12">
        <h4 className="fw-700 font-xs mb-4 mt-2">Deal of the day</h4>
      </div> */}

      <div className="col-lg-12">
        <div className="card border-0">
          <Slider {...discountProductsettings} className="slick-arrow-top">
            {!discountProducts?discountProducts.map((value, index) => (
              <div key={index} className="p-3 posr">
                <h4 className="ls-3 font-xsssss text-white text-uppercase bg-current fw-700 p-2 d-inline-block posa rounded-3">
                  {value.discount}% off
                </h4>
                <span className="posa right-0 top-0 mt-3 me-3 z-index-5">
                  <i className="ti-heart font-xs text-grey-500"></i>
                </span>
                <div className="clearfix"></div>
                <a
                  href={`/single-product/${value._id}`}
                  className="d-block text-center p-2"
                  style={{ height: "150px" }}
                >
                  <img
                    src={value.productImages[1]}
                    alt="product"
                    className="w-100 mt-1 d-inline-block"
                  />
                </a>
                <div className="star d-inline text-left">
                  <img
                    src="assets/images/star.png"
                    alt="star"
                    className="w-1 me-1 float-start"
                  />
                  <img
                    src="assets/images/star.png"
                    alt="star"
                    className="w-1 me-1 float-start"
                  />
                  <img
                    src="assets/images/star.png"
                    alt="star"
                    className="w-1 me-1 float-start"
                  />
                  <img
                    src="assets/images/star.png"
                    alt="star"
                    className="w-1 me-1 float-start"
                  />
                  <img
                    src="assets/images/star-disable.png"
                    alt="star"
                    className="w-1 me-1 float-start"
                  />
                </div>
                <div className="clearfix"></div>
                <h2 className="mt-1">
                  <a
                    href="/single-product-1"
                    className="text-grey-700 fw-600 font-xsss lh-2 ls-0"
                  >
                    {value.productName}
                  </a>
                </h2>
                <h6 className="font-xss ls-3 fw-700 text-current d-flex">
                  <span className="font-xsssss text-grey-500">$</span>
                  {value.price}{" "}
                  <span className="ms-auto me-4 text-grey-500 fw-500 font-xsssss">
                    {value.quantity}
                  </span>
                </h6>
                <div className="cart-count d-flex mt-4 mb-2">
                  <div className="number">
                    <span className="minus">-</span>
                    <input
                      type="text"
                      className="open-font me-1 ms-1"
                      defaultValue="1"
                    />
                    <span className="plus">+</span>
                  </div>
                </div>
              </div>
            )):(<h1>go</h1>)}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default DiscountProducts;
