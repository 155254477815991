import React, { useEffect, useMemo } from "react";
import { Field, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { getCategoryFunc } from "../redux/category";
import { subCategoryValidation } from "../helper/validation";
import { ControlWrapper } from "./forms/ControlWrapper";
import TextField from "./forms/TextField";
import { addSubCategoryAdmin } from "../redux/category/subCategory";

const initialSubCategoryState = {
  name: "",
  nameAr: "",
  category: "",
};

const AddSubCategory = () => {
  const dispatch = useDispatch();

  // Get category data from Redux
  const categories = useSelector((state) => state.getCategory.categories);

  useEffect(() => {
    dispatch(getCategoryFunc());
  }, [dispatch]);

  // Memoize category options for performance
  const categoryOptions = useMemo(() => {
    return Array.isArray(categories)
      ? categories.map((e) => (
          <option value={e._id} key={e._id}>
            {e.name}
          </option>
        ))
      : [];
  }, [categories]);

  const onSubmit = (values, { resetForm }) => {
    dispatch(addSubCategoryAdmin(values)).then(() => {
      resetForm();
    });
  };

  return (
    <Formik
      validationSchema={subCategoryValidation}
      initialValues={initialSubCategoryState}
      onSubmit={onSubmit}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-md-12 mb-3">
              <label className="mont-font fw-600 font-xssss mb-2 white-text">
                chose the category that you want to add sub category for
              </label>
              <ControlWrapper name="category">
                <Field
                  as="select"
                  className="form-control"
                  name="category"
                  style={{ lineHeight: "20px" }}
                >
                  <option value="">Select category</option>
                  {categoryOptions}
                </Field>
              </ControlWrapper>
            </div>
            <div className="col-lg-6 mb-3">
              <label className="mont-font fw-600 font-xssss mb-2 white-text">
                sub category
              </label>
              <TextField
                type="text"
                className="form-control theme-black-bg rounded-10"
                name="name"
                label="SubCategory"
              />
            </div>
            <div className="col-lg-6 mb-3">
              <label className="mont-font fw-600 font-xssss mb-2 white-text">
                sub category in Arabic
              </label>
              <TextField
                type="text"
                className="form-control theme-black-bg rounded-10"
                name="nameAr"
                label="SubCategory (Arabic)"
              />
            </div>
            <div className="col-lg-12">
              <button
                type="submit"
                className="w-100 p-3 bg-current font-xsss ls-1 fw-600 text-white rounded-10 d-block text-center"
              >
                Add
              </button>
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default AddSubCategory;
