import * as Yup from "yup";

export const validationRegSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Your name is too short")
    .required("Please enter your full name"),
  password: Yup.string()
    .min(6, "Your password is too short")
    .required("Please enter your password"),
  email: Yup.string()
    .email("The email is incorrect")
    .required("Please enter your email"),
  passwordConfirm: Yup.string()
    .required("Confirm your password!!")
    .oneOf([Yup.ref("password"), null], "Passwords must match"),

  role: Yup.string().required("Please specify your role!"),
  phoneNumber: Yup.number().required("Please enter your phone number!"),
});

export const validationLogInSchema = Yup.object().shape({
  password: Yup.string()
    .min(6, "Your password is too short")
    .required("Please enter your password"),
  email: Yup.string()
    .email("The email is incorrect")
    .required("Please enter your email"),
});

export const validationCompanyInfoSchema = Yup.object().shape({
  arabicName: Yup.string()
    .min(2, "Your name is too short")
    .required("Please enter your name in arabic"),
  idNumber: Yup.string()
    .min(6, "Your ID number is too short")
    .required("Please enter your ID number"),
  companyName: Yup.string()
    .min(2, "Your name is too short")
    .required("Please enter your company name"),
  secondPhoneNo: Yup.number().required("Please enter your phone number!"),
  companyDescription: Yup.string().required("Please describe your company!!"),
  companyDescriptionAr: Yup.string().required(
    "Please describe your company in arabic !!"
  ),
  businessDetail: Yup.string(),
});

export const addProductValidation = Yup.object().shape({
  productName: Yup.string()
    .min(2, "Your product name is too short")
    .required("Please enter the product name!"),
  arabicProductName: Yup.string()
    .min(2, "Your arabic product name is too short")
    .required("Please enter the arabic product name!"),
  quantity: Yup.number().required("Please enter quantity of the product!"),
  category: Yup.string().required(
    "Please chose the category for your product!"
  ),
  brand: Yup.string(),
  subCategory: Yup.string().required(
    "Please chose the sub category for your product!"
  ),
  price: Yup.number().required("Please enter the price"),
  description: Yup.string().required(
    "Please add a description for your product!!"
  ),
  arabicDescription: Yup.string().required(
    "Please add a description in Arabic for your product!!"
  ),
  discount: Yup.number(),
  tag: Yup.string(),
  visibility: Yup.string().required("Visibility is required"),
  publishDate: Yup.string().when("visibility", {
    is: (value) => value === "Schedule",
    then: (schema) => schema.required("Schedule Date is required"),
  }),
  productImages: Yup.array()
    .min(3, "You need to upload at least 3 images.")
    .required("Images are required"),
});

export const validationShippingSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Your name is too short")
    .required("Please enter your full name"),
  email: Yup.string()
    .email("The email is incorrect")
    .required("Please enter your email"),
  phoneNumber: Yup.number().required("Please enter your phone number!"),
  address: Yup.string()
    .min(2, "Your address is too short make it clear please")
    .required("Please enter clear address"),
  city: Yup.string().required("Please enter city"),
});

export const categoryValidation = Yup.object().shape({
  name: Yup.string()
    .min(2, "category name is too short")
    .required("Please enter your full category name"),
  nameAr: Yup.string()
    .min(2, "arabic category name is too short")
    .required("Please enter your full arabic category name"),
});

export const subCategoryValidation = Yup.object().shape({
  name: Yup.string()
    .min(2, "sub category name is too short")
    .required("Please enter your full sub category name"),
  nameAr: Yup.string()
    .min(2, "arabic sub category name is too short")
    .required("Please enter your full arabic sub category name"),
});
