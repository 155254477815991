import WrapBackGround from "./wrapper";
import React from "react";
import ReactDOM from "react-dom";

const container = document.createElement("div");
document.querySelector("body").appendChild(container);

const LoaderPortal = ({ children }) => {
  return ReactDOM.createPortal(children, container);
};

export const Loader = ({ show = false, setShow }) => {
  return (
    <LoaderPortal>
      {show ? (
        <WrapBackGround setOpen={setShow}>
          <div
            class="spinner-border text-success"
            style={{ width: "4rem", height: "4rem" }}
            role="status"
          >
            <span class="sr-only"></span>
          </div>
          <span className="text-success m-3 fw-600 ">Loading...</span>
        </WrapBackGround>
      ) : null}
    </LoaderPortal>
  );
};
