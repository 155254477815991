import React, { Fragment, useRef, useState } from "react";
import { Formik } from "formik";
import TextField from "../components/forms/TextField";
import { ControlWrapper } from "../components/forms/ControlWrapper";
import classNames from "classnames";
import { validationRegSchema } from "../helper/validation";
import { Loader } from "../components/Loader";
import axios from "axios";
import { Toast } from "primereact/toast";
import pro from "../../src/PRO2.png"

const initialValues = {
  name: "",
  email: "",
  password: "",
  passwordConfirm: "",
  role: "",
  phoneNumber: "",
};

const roles = ["Service", "Restaurant"];
const API_URL = "https://770d-178-130-149-192.ngrok-free.app/api/v1/auth/signup";

const RegisterTwo = () => {
  const [loader, setLoader] = useState(false);
  const toast = useRef(null);

  const onSubmit = async (values) => {
    setLoader(true);

    try {
      const response = await axios.post(API_URL, values);
      localStorage.setItem("user", JSON.stringify(response.data));
      window.location.href = "/dashboard";
    } catch (error) {
      const errorMessage =
        error.response?.data?.errors?.[0]?.msg || "An error occurred";
      toast.current.show({
        severity: "error",
        summary: "Registration Error",
        detail: errorMessage,
        life: 10000,
      });
    } finally {
      setLoader(false);
    }
  };

  return (
    <Fragment>
      <Toast ref={toast} />
      <div className="main-wrap min-vh-100">
        <div className="nav-header bg-transparent shadow-none border-0 position-fixed zindex-900 w-100 p-3 text-center d-lg-block d-none">
          <a href="/">
            <img src={pro} alt="logo" className="w-125" />
          </a>
        </div>

        <div className="row min-vh-100">
          <div className="card shadow-lg border-0 ms-auto me-auto login-card mt-auto mb-auto zindex-100 rounded-10 theme-dark-bg">
            <div className="card-body rounded-0 text-start p-3">
              <h2 className="fw-700 display1-size display2-md-size mb-2 mt-1 white-text text-grey-900">
                Create <br />
                your account
              </h2>

              <Formik
                validationSchema={validationRegSchema}
                initialValues={initialValues}
                onSubmit={onSubmit}
              >
                {({ handleSubmit, values, setFieldValue }) => (
                  <form onSubmit={handleSubmit}>
                    <Loader show={loader} />
                    <div className="form-group icon-input mb-2">
                      <i className="font-sm ti-user text-grey-500 pe-0"></i>
                      <TextField
                        name="name"
                        type="text"
                        className="style2-input ps-5 form-control text-grey-900 white-text font-xsss fw-600"
                        placeholder="Your Name"
                      />
                    </div>
                    <div className="form-group icon-input mb-2">
                      <i className="font-sm ti-email text-grey-500 pe-0"></i>
                      <TextField
                        name="email"
                        type="text"
                        className="style2-input ps-5 form-control text-grey-900 white-text font-xsss fw-600"
                        placeholder="Email Address"
                      />
                    </div>
                    <div className="form-group icon-input mb-2">
                      <TextField
                        name="password"
                        type="Password"
                        className="style2-input ps-5 form-control text-grey-900 white-text font-xss ls-3"
                        placeholder="Password"
                      />
                      <i className="font-sm ti-lock text-grey-500 pe-0"></i>
                    </div>
                    <div className="form-group icon-input mb-1">
                      <TextField
                        name="passwordConfirm"
                        type="Password"
                        className="style2-input ps-5 form-control text-grey-900 white-text font-xss ls-3"
                        placeholder="Confirm Password"
                      />
                      <i className="font-sm ti-lock text-grey-500 pe-0"></i>
                    </div>
                    <div className="form-group icon-input mb-2">
                      <TextField
                        name="phoneNumber"
                        type="text"
                        className="style2-input ps-5 form-control text-grey-900 white-text font-xss ls-3"
                        placeholder="Phone Number"
                      />
                      <i className="font-sm ti-lock text-grey-500 pe-0"></i>
                    </div>
                    <div className="  form-group icon-input mb-1">
                      <ControlWrapper name="role">
                        <div className="d-flex ">
                          {roles.map((role, index) => (
                            <button
                              type="button"
                              key={role}
                              className={classNames(
                                " m-1 text-center font-xsss style2-input  fw-600 text-success border-0 p-0 w-50 ",
                                {
                                  "btn btn-success text-white":
                                    values.role === role,
                                  "mr-2": index === 0,
                                }
                              )}
                              onClick={() => setFieldValue("role", role)}
                            >
                              {role}
                            </button>
                          ))}
                        </div>
                      </ControlWrapper>
                    </div>
                    <div className="form-check text-start mb-2">
                      <input
                        type="checkbox"
                        className="form-check-input mt-2"
                        id="exampleCheck3"
                      />
                      <label
                        className="form-check-label font-xssss text-grey-500 white-text"
                        for="exampleCheck3"
                      >
                        Accept Term and Conditions
                      </label>
                    </div>
                    <div className="col-sm-12 p-0 text-start">
                      <div className="form-group mb-1">
                        <button
                          type="submit"
                          className="text-center font-xsss style2-input text-white fw-600 bg-current border-0 p-0 w-100 "
                        >
                          Register
                        </button>
                      </div>
                      <h6 className="text-grey-500 font-xsss fw-500 mt-0 mb-0 lh-32 white-text">
                        Already have account{" "}
                        <a href="logInTwo" className="fw-700 ms-1">
                          Login
                        </a>
                      </h6>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default RegisterTwo;
