import { useEffect, useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Formik, Field } from "formik";
import { addProductValidation } from "../../helper/validation";
import TextField from "../forms/TextField";
import { ControlWrapper } from "../forms/ControlWrapper";
import { Col, Row } from "react-bootstrap";
import { Toast } from "primereact/toast";
import { addProductFunction } from "../../redux/products/addProductReducer";
import { useDispatch, useSelector } from "react-redux";
import { getCategoryFunc } from "../../redux/category";
import { Loader } from "../Loader";

const initialValues = {
  productName: "",
  arabicProductName: "",
  quantity: "",
  category: "",
  subCategory: "",
  description: "",
  arabicDescription: "",
  price: "",
  discount: "",
  tag: "",
  visibility: "Published",
  publishDate: "",
  productImages: [],
  userId: "",
  brand: "",
};

const AddProduct = ({ show, setShow }) => {
  const [loader, setLoader] = useState(false);
  const [fullscreen] = useState(true);
  const [specificCategory, setSpecificCategory] = useState([]);

  // Toast
  const toast = useRef(null);

  // User
  const user = JSON.parse(localStorage.getItem("user"));
  const userId = user.data._id;

  // Redux
  const dispatch = useDispatch();
  const categories = useSelector((state) => state.getCategory.categories);
  const categoriesStatus = useSelector((state) => state.getCategory.status);
  const addProductStatus = useSelector((state) => state.addProduct.status);

  useEffect(() => {
    if (categoriesStatus === "initial") {
      dispatch(getCategoryFunc());
    }

    switch (addProductStatus) {
      case "loading":
        setLoader(true);
        break;
      case "succeeded":
        setLoader(false);
        // toast.current.show({
        //   severity: "success",
        //   summary: "Product Added",
        //   detail: "Thank you for adding products",
        //   life: 30000,
        // });
        break;
      case "failed":
        setLoader(false);
        // toast.current.show({
        //   severity: "error",
        //   summary: "Error !!",
        //   detail: "Oops.. !! It seems there is an error!",
        //   life: 30000,
        // });
        break;

      default:
        setLoader(false);
    }
  }, [addProductStatus, dispatch, categoriesStatus]);

  const handleImgChange = (event, setFieldValue, images) => {
    let { files } = event.target;
    let reader = new FileReader();
    reader.readAsDataURL(files[0]);
    let newImages = [...images];

    reader.onload = (e) => {
      newImages.push(reader.result);
      setFieldValue("productImages", newImages);
    };
  };

  // Handle category selection and update subcategories
  const handleCategoryChange = (e, setFieldValue) => {
    const selectedCategoryId = e.target.value;

    // Find the selected category object
    const selectedCategory = categories.find(
      (category) => category._id === selectedCategoryId
    );

    // Update the subCategories state with the selected category's subCategories
    setSpecificCategory(selectedCategory ? selectedCategory.subCategory : []);

    // Set the value of the 'category' field in Formik
    setFieldValue("category", selectedCategoryId);
  };

  const onSubmit = (values, { resetForm }) => {
    values.userId = userId;
    dispatch(addProductFunction(values)).then(() => {
      resetForm();
      window.location.href = "/products";
    });
  };

  return (
    <>
      <Toast ref={toast} />
      <Modal
        show={show}
        onHide={() => setShow(false)}
        fullscreen={fullscreen}
        dialogClassName="modal-10w"
        aria-labelledby="example-custom-modal-styling-title"
        style={{ backgroundColor: "red !important" }}
      >
        <Modal.Header closeButton>
          <Modal.Title
            className=" fw-700"
            id="example-custom-modal-styling-title"
          >
            Create a New Product
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ backgroundColor: "#f2f3f3" }}>
          <Formik
            validationSchema={addProductValidation}
            initialValues={initialValues}
            onSubmit={onSubmit}
          >
            {({ handleSubmit, values, setFieldValue, handleChange }) => (
              <form onSubmit={handleSubmit}>
                <Loader show={loader} />
                {/* General Information */}
                <div
                  className="d-flex"
                  style={{ justifyContent: "space-around" }}
                >
                  <div
                    className="card mb-4 w-50 "
                    style={{ height: "fit-content", borderRadius: "15px" }}
                  >
                    <div className="card-body">
                      <h2 className=" fw-700">General Information</h2>
                      <div className="row">
                        <div className="col-md-6 mb-3">
                          <label className="form-label fw-700">
                            Product Name
                          </label>
                          <TextField
                            name="productName"
                            type="text"
                            className="form-control"
                            placeholder="Lightweight Air Mesh Men Shoes"
                          />
                        </div>
                        <div className="col-md-6 mb-3">
                          <label className="form-label fw-700">
                            Product Name in Arabic
                          </label>
                          <TextField
                            name="arabicProductName"
                            type="text"
                            className="form-control"
                            placeholder="Lightweight Air Mesh Men Shoes"
                          />
                        </div>
                        <div className="col-md-6 mb-3">
                          <label className="form-label fw-700">Quantity</label>
                          <TextField
                            name="quantity"
                            type="number"
                            className="form-control"
                            placeholder="0"
                          />
                        </div>
                        <div className="col-md-6 mb-3">
                          <label className="form-label fw-700">Brand</label>
                          <TextField
                            name="brand"
                            type="text"
                            className="form-control"
                            placeholder="brand"
                          />
                        </div>
                      </div>
                      {/* //////////////////////////////////////////// */}
                      <div className="row">
                        <div className="col-md-6 mb-3">
                          <h5 className=" fw-700">Publish Category</h5>
                          <ControlWrapper name={"category"}>
                            <Field
                              as="select"
                              className="form-control"
                              name="category"
                              onChange={(e) =>
                                handleCategoryChange(e, setFieldValue)
                              }
                              style={{ lineHeight: "20px" }}
                            >
                              <option value="">Select category</option>
                              {categories.map((e, key) => (
                                <option value={e._id} key={e._id}>
                                  {e.name}
                                </option>
                              ))}
                            </Field>
                          </ControlWrapper>
                        </div>
                        <div className="col-md-6 mb-3">
                          <h5 className=" fw-700">Publish subCategory</h5>
                          <ControlWrapper name={"subCategory"}>
                            <Field
                              as="select"
                              className="form-control"
                              name="subCategory"
                              style={{ lineHeight: "20px" }}
                            >
                              <option value="shoes">Select sub category</option>
                              {console.log(specificCategory,"supcategory")}
                              {specificCategory.map((e, key) => (
                                <option value={e._id} key={e._id}>
                                  {e.name}
                                </option>
                              ))}
                            </Field>
                          </ControlWrapper>
                        </div>
                      </div>{" "}
                      {/* ///////////////////////////////////////////////// */}
                      <div className="mb-3 ">
                        <label className="form-label fw-700">Description</label>
                        <ControlWrapper name={"description"}>
                          <Field
                            as="textarea"
                            className="form-control h-150 lh-2"
                            name="description"
                            placeholder="Enter product description"
                            rows="10"
                          />
                        </ControlWrapper>
                      </div>
                      <div className="mb-3 ">
                        <label className="form-label fw-700">
                          Description in Arabic
                        </label>
                        <ControlWrapper name={"arabicDescription"}>
                          <Field
                            as="textarea"
                            className="form-control h-150 lh-1"
                            name="arabicDescription"
                            placeholder="Enter product description"
                            rows="10"
                          />
                        </ControlWrapper>
                      </div>
                      <div className="row">
                        <div className="col-md-6 mb-3">
                          <label className="form-label fw-700">
                            Sale Price
                          </label>

                          <TextField
                            name="price"
                            type="number"
                            className="form-control"
                            placeholder="0"
                          />
                        </div>
                        <div className="col-md-6 mb-3">
                          <label className="form-label fw-700">Discount</label>
                          <div className="input-group">
                            <TextField
                              name="discount"
                              type="number"
                              className="form-control"
                              placeholder="0"
                            />
                            <span
                              className="input-group-text "
                              style={{ height: "50px" }}
                            >
                              %
                            </span>
                          </div>
                        </div>
                      </div>
                      {/* Size Selection */}
                    </div>
                  </div>

                  {/* Right Sidebar Options */}
                  <div style={{ width: "45%" }}>
                    <div
                      className="card mb-4 "
                      style={{ borderRadius: "15px" }}
                    >
                      <div className="">
                        <div className="card-body">
                          <h5 className="card-title fw-700">Tag</h5>
                          <TextField
                            name="tag"
                            type="text"
                            className="form-control"
                            placeholder="Type and enter"
                          />
                        </div>
                      </div>
                      {/* <AddProductForm /> */}

                      <div className="">
                        <div className="card-body ">
                          <h5 className="card-title fw-700">Product Image</h5>
                          <div
                            className="d-flex"
                            style={{ justifyContent: "space-between" }}
                          >
                            <div className="upload-area border p-4 text-center">
                              <div className="mb-2">
                                Drop your images or selected{" "}
                                <label
                                  htmlFor="file-upload"
                                  className="text-primary"
                                >
                                  click to browse
                                </label>
                              </div>
                              <ControlWrapper name={"productImages"}>
                                <input
                                  name={"productImages"}
                                  onChange={(event) =>
                                    handleImgChange(
                                      event,
                                      setFieldValue,
                                      values.productImages
                                    )
                                  }
                                  id="file-upload"
                                  type="file"
                                  multiple
                                  accept="image/*"
                                  className="d-none"
                                />
                              </ControlWrapper>
                            </div>

                            <div className="container">
                              <Row className="mt-3">
                                {values.productImages.map((img, index) => (
                                  <Col
                                    xs={6}
                                    md={4}
                                    key={index}
                                    className="mb-2"
                                  >
                                    <img
                                      src={img}
                                      alt={`Uploaded preview ${index + 1}`}
                                      className="img-fluid rounded"
                                      style={{ border: "1px solid red" }}
                                    />
                                  </Col>
                                ))}
                              </Row>
                            </div>
                          </div>
                          <small className="text-muted">
                            ! You need at least 3 images. Pay attention to the
                            quality of the pictures you add (important)
                          </small>
                        </div>
                      </div>
                    </div>

                    <div className="card mb-4" style={{ borderRadius: "15px" }}>
                      <div className="card-body">
                        <ControlWrapper name={"visibility"}>
                          <h5 className="card-title fw-700">Visibility</h5>
                          <div className="form-check">
                            <Field
                              as="input"
                              className="form-check-input"
                              type="radio"
                              name="visibility"
                              id="published"
                              value="Published"
                              checked={values.visibility === "Published"}
                              onChange={handleChange}
                            />

                            <label
                              className="form-check-label"
                              htmlFor="published"
                            >
                              Published
                            </label>
                          </div>
                          <div className="form-check">
                            <Field
                              as="input"
                              className="form-check-input"
                              type="radio"
                              name="visibility"
                              id="schedule"
                              value="Schedule"
                              checked={values.visibility === "Schedule"}
                              onChange={handleChange}
                            />

                            <label
                              className="form-check-label"
                              htmlFor="schedule"
                            >
                              Schedule
                            </label>
                          </div>
                          <div className="form-check">
                            <Field
                              as="input"
                              className="form-check-input"
                              type="radio"
                              name="visibility"
                              id="hidden"
                              value="Hidden"
                              placeholder="Type and enter"
                              checked={values.visibility === "Hidden"}
                              onChange={handleChange}
                            />

                            <label
                              className="form-check-label"
                              htmlFor="hidden"
                            >
                              Hidden
                            </label>
                          </div>
                          {values.visibility === "Schedule" && (
                            <div className="mt-3">
                              <label className="form-label fw-700">
                                Schedule Date
                              </label>
                              <ControlWrapper name={"publishDate"}>
                                <Field
                                  as="input"
                                  type="date"
                                  className="form-control"
                                  name="publishDate"
                                  value={values.publishDate}
                                  onChange={(e) =>
                                    setFieldValue("publishDate", e.target.value)
                                  }
                                />
                              </ControlWrapper>
                            </div>
                          )}
                        </ControlWrapper>
                      </div>
                    </div>

                    {/* Action Buttons */}
                  </div>
                </div>

                <div className="col-md-8 d-flex align-items-center justify-content-center w-100">
                  <button
                    type="button"
                    className="btn btn-outline-secondary me-3"
                    onClick={() => setShow(false)}
                  >
                    Cancel
                  </button>
                  <button type="button" className="btn btn-secondary me-3">
                    Publish and add another
                  </button>
                  <button type="submit" className="btn btn-primary">
                    Publish
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddProduct;
