import React from "react";
import ReactDOM from "react-dom";

import "./style/scss/main.scss";
import store from "./redux/store";

// Common Layout

// import { BrowserRouter, Switch, Route } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import App from "./App";
import ToastManager from "./helper/toastManager";

const Root = () => {
  return (
    <Provider store={store}>
      <React.StrictMode>
        <ToastManager />
        <App />
      </React.StrictMode>
    </Provider>
  );
};

ReactDOM.render(<Root />, document.getElementById("root"));
serviceWorker.register();
