import { configureStore } from "@reduxjs/toolkit";
import userInfo from "./userInfo";
import addProduct from "./products/addProductReducer";
import getCategory from "./category";
import getProducts from "./products/getProducts";
import cart from "./Cart";
import order from "./order";
import toastReducer from "./toast";
import toastMiddleware from "./toast/toastMiddleware";
import admin from "./admin";
import subCategory from "./category/subCategory";

export default configureStore({
  reducer: {
    SalersInfo: userInfo,
    addProduct: addProduct,
    getCategory: getCategory,
    getProducts: getProducts,
    cart: cart,
    order: order,
    toast: toastReducer,
    admin: admin,
    subCategory: subCategory,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: [
          "/get-products/fulfilled",
          "/get-category/fulfilled",
          "/add-product/fulfilled",
          "/get-pointedProduct/fulfilled",
          "/add-to-cart/fulfilled",
          "/get-cart/fulfilled",
          "/remove-product/fulfilled",
          "/create-order/fulfilled",
          "/get-order/fulfilled",
          "/get-service/fulfilled",
          "/get-restaurant/fulfilled",
          "/get-orders/fulfilled",
          "/get-notifications/fulfilled",
          "salers/Info/fulfilled",
          "/add-category/fulfilled",
          "/user-activate/fulfilled",
          "/set-Read/fulfilled",
          "/get-products-admin/fulfilled",
          "/get-orders-admin/fulfilled",
          "/add-subCategory-admin/fulfilled",
          "/delete-category/fulfilled",
          "/get-products-general/fulfilled",
          "/get-products-discount/fulfilled",
        ],

        // Ignore these paths in the state
        ignoredPaths: ["payload.headers"],
      },
    }).concat(toastMiddleware),
});
