import React, { Fragment, useEffect, useRef, useState } from "react";

import Header from "../../components/Header";
import Headermob from "../../components/Headermob";
import Upperheader from "../../components/Upperheader";
import Lowerheader from "../../components/Lowerheader";
import Footer from "../../components/Footer";
import Dashnav from "../../components/Dashnav";
import Dashmenu from "../../components/Dashmenu";
import { Formik } from "formik";
import { validationCompanyInfoSchema } from "../../helper/validation";
import TextField from "../../components/forms/TextField";
import ModalInst from "../../components/Modal/addInfoDone";
import { Loader } from "../../components/Loader";
import { useDispatch, useSelector } from "react-redux";
import {
  getRestaurantInfo,
  getSalersInfo,
  provideRestaurantInfo,
  provideServiceInfo,
} from "../../redux/userInfo";
import { Toast } from "primereact/toast";
import { ControlWrapper } from "../../components/forms/ControlWrapper";
import { Col } from "react-bootstrap";

const Address = ({ initialValues }) => {
  // State
  const [done, setDone] = useState(false);
  const [loader, setLoader] = useState(false);
  const [renderImage, setRenderImage] = useState("");

  // User
  const user = JSON.parse(localStorage.getItem("user")).data;

  // Redux
  const dispatch = useDispatch();
  const info = useSelector((state) => state.SalersInfo.info);

  // Toast
  const toast = useRef(null);

  const onSubmit = (values, { resetForm }) => {
    const formData = new FormData();

    setLoader(true);
    if (user.role === "Service") {
      formData.append("arabicName", values.arabicName);
      formData.append("idNumber", values.idNumber);
      formData.append("businessDetail", values.businessDetail);
      formData.append("companyDescription", values.companyDescription);
      formData.append("companyDescriptionAr", values.companyDescriptionAr);
      formData.append("companyName", values.companyName);
      formData.append("personalImage", values.personalImage);
      formData.append("secondPhoneNo", values.secondPhoneNo);
      formData.append("userId", user._id);

      console.log(formData, "here is the data");

      dispatch(provideServiceInfo(formData))
        .unwrap()
        .then(() => {
          setLoader(false);
          resetForm();
          setRenderImage("");
        })
        .catch(() => {
          setLoader(false);
        });
    } else if (user.role === "Restaurant") {
      formData.append("arabicName", values.arabicName);
      formData.append("idNumber", values.idNumber);
      formData.append("secondPhoneNo", values.secondPhoneNo);
      formData.append("description", values.description);
      formData.append("city", values.city);
      formData.append("street", values.street);
      formData.append("moreAddressDetail", values.moreAddressDetail);
      formData.append("personalImage", values.personalImage);
      dispatch(provideRestaurantInfo(formData))
        .unwrap()
        .then(() => {
          setLoader(false);
          resetForm();
        })
        .catch(() => {
          setLoader(false);
        });
    }
  };

  useEffect(() => {
    if (user.role === "Restaurant") {
      dispatch(getRestaurantInfo());
    } else if (user.role === "Service") {
      dispatch(getSalersInfo());
    } else return;
  }, [dispatch, user.role]);

  const handleImgChange = (event, setFieldValue) => {
    let { files } = event.target;

    let reader = new FileReader();
    reader.readAsDataURL(files[0]);
    // let newImages = [...images];
    setFieldValue("personalImage", files[0]);

    reader.onload = (e) => {
      setRenderImage(reader.result);
    };
  };

  return (
    <Fragment>
      <Headermob />
      <Upperheader />
      <Header />
      <Lowerheader />
      <Dashnav title="Saved Address" />
      <ModalInst show={done} onHide={() => setDone(false)} />
      <Toast ref={toast} />
      <div className="main-div pb-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <Dashmenu userName={user.name} />
            </div>
            <div className="col-lg-8 pt-5 ps-4">
              <div className="card border-0">
                {info && (
                  <div className="card-body mb-3 bg-lightblue p-4 rounded-10">
                    <h4 className="fw-700 mt-2 font-xss text-grey-900 d-flex mb-0">
                      {user.name} && {info.arabicName}
                      <span className="bg-primary text-white font-xsssss fw-600 ls-3 p-2 rounded-6 ms-auto">
                        {user.role}
                      </span>
                    </h4>
                    <h6 className="fw-500 font-xssss text-grey-600 lh-22 mb-0">
                      {info.companyName}
                      <br /> {info.companyDescription} <br />
                      {info.companyDescriptionAr}
                    </h6>
                  </div>
                )}
                {/* <div className="card-body mb-3 bg-lightgreen p-4 rounded-10">
                  <h4 className="fw-700 mt-2 font-xss text-grey-900 d-flex mb-0">
                    Surfiya Zakir{" "}
                    <span className="bg-success text-white font-xsssss fw-600 ls-3 p-2 rounded-6 ms-auto">
                      OFFICE
                    </span>
                  </h4>
                  <h6 className="fw-500 font-xssss text-grey-600 lh-22 mb-0">
                    Manikpur, Thakurpara PO Ital Gacha 2 no Airport Gate <br />{" "}
                    Thakur Para United Club, Kolkata <br /> West Bengal - 700079
                  </h6>
                </div> */}

                <Formik
                  validationSchema={validationCompanyInfoSchema}
                  initialValues={initialValues}
                  onSubmit={onSubmit}
                >
                  {({ handleSubmit, values, setFieldValue }) => (
                    <form onSubmit={handleSubmit}>
                      <Loader show={loader} setShow={setLoader} />
                      <div className="row">
                        <div className="col-lg-6 mb-3">
                          <div className="form-group">
                            <label className="mont-font fw-600 font-xssss mb-2 white-text">
                              Name in Arabic
                            </label>
                            <TextField
                              type="text"
                              className="form-control theme-black-bg rounded-10"
                              name="arabicName"
                            />
                          </div>
                        </div>

                        <div className="col-lg-6 mb-3">
                          <div className="form-group">
                            <label className="mont-font fw-600 font-xssss mb-2 white-text">
                              ID number
                            </label>

                            <TextField
                              type="text"
                              className="form-control theme-black-bg rounded-10"
                              name="idNumber"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        {/* ///////////// */}
                        <div className="col-lg-6 mb-3">
                          {renderImage ? (
                            <Col xs={6} md={4} className="mb-2">
                              <img
                                src={renderImage}
                                alt={"personalImage"}
                                className="img-fluid rounded"
                                style={{ border: "1px solid red" }}
                                onClick={() => setRenderImage("")}
                              />
                            </Col>
                          ) : (
                            <div className="upload-area border p-4 text-center">
                              <div className="mb-2">
                                Drop your images or selected{" "}
                                <label
                                  htmlFor="file-upload"
                                  className="text-primary"
                                >
                                  click to browse
                                </label>
                              </div>
                              <ControlWrapper name={"personalImage"}>
                                <input
                                  name={"personalImage"}
                                  onChange={(event) =>
                                    handleImgChange(
                                      event,
                                      setFieldValue,
                                      values.productImages
                                    )
                                  }
                                  id="file-upload"
                                  type="file"
                                  multiple
                                  accept="image/*"
                                  className="d-none"
                                />
                              </ControlWrapper>
                            </div>
                          )}
                        </div>

                        {/* Here but the image field  */}

                        {/* /////////////////////////////////////////////////////////////////// */}
                        <div className="col-lg-6 mb-3">
                          <div className="form-group">
                            <label className="mont-font fw-600 font-xssss mb-2 white-text">
                              Add another phone number
                            </label>
                            <TextField
                              type="text"
                              className="form-control theme-black-bg rounded-10"
                              name="secondPhoneNo"
                            />
                          </div>
                        </div>
                      </div>

                      {user.role === "Service" ? (
                        <div className="row">
                          <div className="col-lg-6 mb-3">
                            <div className="form-group">
                              <label className="mont-font fw-600 font-xssss mb-2 white-text">
                                Company Name
                              </label>
                              <TextField
                                type="text"
                                className="form-control theme-black-bg rounded-10"
                                name="companyName"
                              />
                            </div>
                          </div>

                          <div className="col-lg-6 mb-3">
                            <div className="form-group">
                              <label className="mont-font fw-600 font-xssss mb-2 white-text">
                                business details
                              </label>
                              <TextField
                                type="text"
                                className="form-control theme-black-bg rounded-10"
                                name="businessDetail"
                              />
                            </div>
                          </div>
                        </div>
                      ) : null}

                      <div className="row">
                        <div className="col-lg-12 mb-3">
                          <div className="form-group">
                            <label className="mont-font fw-600 font-xssss mb-2 white-text">
                              {user.role === "Service"
                                ? "Describe your company"
                                : "City"}
                            </label>
                            <TextField
                              type="text"
                              className="form-control theme-black-bg rounded-10"
                              name={
                                user.role === "Service"
                                  ? "companyDescription"
                                  : "city"
                              }
                            />
                          </div>
                        </div>

                        <div className="col-lg-12 mb-3">
                          <div className="form-group">
                            <label className="mont-font fw-600 font-xssss mb-2 white-text">
                              {user.role === "Service"
                                ? "Describe your company in Arabic"
                                : "Street"}
                            </label>
                            <TextField
                              type="text"
                              className="form-control theme-black-bg rounded-10"
                              name={
                                user.role === "Service"
                                  ? "companyDescriptionAr"
                                  : "street"
                              }
                            />
                          </div>
                        </div>
                        {user.role === "Restaurant" ? (
                          <div className="col-lg-12 mb-3">
                            <div className="form-group">
                              <label className="mont-font fw-600 font-xssss mb-2 white-text">
                                more Address Detail
                              </label>
                              <TextField
                                type="text"
                                className="form-control theme-black-bg rounded-10"
                                name="moreAddressDetail"
                              />
                            </div>
                          </div>
                        ) : null}

                        <div className="col-lg-12">
                          <button
                            type="submit"
                            className="w-100 p-3 bg-current font-xsss ls-1 fw-600 text-white rounded-10 d-block text-center"
                          >
                            {info ? "update information" : "Save"}
                          </button>
                        </div>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
};

export default Address;
