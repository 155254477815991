import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteCategoryAdmin, getCategoryFunc } from "../redux/category";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const CategoryComponent = ({ adminPage = false }) => {
  // Redux
  const dispatch = useDispatch();
  const categories = useSelector((state) => state.getCategory.categories);

  useEffect(() => {
    if (!categories.length) dispatch(getCategoryFunc());
  }, [dispatch, categories]);

  return (
    <>
      {categories.map((item, key) => (
        <div className="col-md-3 col-xs-6 sm-mb-3" key={key}>
          <h3 className="d-flex mb-0 justify-content-between align-items-center">
            {item.name}
            {adminPage && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-x"
                viewBox="0 0 16 16"
                style={{ color: "red", cursor: "pointer" }}
                onClick={() => dispatch(deleteCategoryAdmin(item._id))}
              >
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
              </svg>
            )}
          </h3>
          <ul className="menu">
            {item.subCategory?.map((item, key) => (
              <li key={item._id}>
                <Link to="/">{item.name}</Link>
              </li>
            ))}
          </ul>
          <div className="col-lg-12 pt-5 mt-5"></div>
        </div>
      ))}
    </>
  );
};

export default CategoryComponent;
