import React, { useState, useEffect } from "react";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { InputNumber } from "primereact/inputnumber";
import { Tag } from "primereact/tag";
import { Calendar } from "primereact/calendar";

export default function OrderTables({ orders }) {
  const [customers, setCustomers] = useState();
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    productName: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    quantity: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    price: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    isPaid: { value: null, matchMode: FilterMatchMode.EQUALS },
    isDelivered: { value: null, matchMode: FilterMatchMode.EQUALS },
    createdAt: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
    },
  });
  const [loading, setLoading] = useState(false);
  const [globalFilterValue, setGlobalFilterValue] = useState("");

  /////////////////////////////////////////////////////////////////////////////////////////////

  const formatDate = (value) => {
    if (!value) return "N/A";

    return value.toLocaleDateString("en-US", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
  };

  const dateBodyTemplate = (rowData) => {
    return formatDate(rowData.createdAt);
  };

  const dateFilterTemplate = (options) => {
    return (
      <Calendar
        value={options.value}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        dateFormat="mm/dd/yy"
        placeholder="mm/dd/yyyy"
        mask="99/99/9999"
      />
    );
  };

  useEffect(() => {
    // CustomerService.getCustomersMedium().then((data) => {
    setCustomers(getCustomers(orders));
    setLoading(false);
    // });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getCustomers = (data) => {
    if (!Array.isArray(data)) return [];

    return data.map((d) => {
      return {
        ...d, // Spread the original object
        createdAt: d.createdAt ? new Date(d.createdAt) : null, // Safely parse the date and assign it to a new object
      };
    });
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const renderHeader = () => {
    return (
      <div className="flex justify-content-end">
        <IconField iconPosition="left">
          <InputIcon className="pi pi-search" />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Keyword Search"
          />
        </IconField>
      </div>
    );
  };

  const getSeverity = (status) => {
    // eslint-disable-next-line default-case
    switch (status) {
      case true:
        return "success";

      case false:
        return "warning";
    }
  };

  const statusBodyTemplate = (rowData) => {
    return (
      <Tag
        value={rowData.isPaid ? "paid" : "not paid"}
        severity={getSeverity(rowData.isPaid)}
      />
    );
  };

  const statusBodyTemplateIsDelivered = (rowData) => {
    return (
      <Tag
        value={rowData.isDelivered ? "delivered" : "not delivered"}
        severity={getSeverity(rowData.isDelivered)}
      />
    );
  };

  const formatCurrency = (value) => {
    return value.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
  };
  const balanceBodyTemplate = (rowData) => {
    return formatCurrency(rowData.price);
  };

  const balanceFilterTemplate = (options) => {
    return (
      <InputNumber
        value={options.value}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        mode="currency"
        currency="USD"
        locale="en-US"
      />
    );
  };

  const imageBodyTemplate = (data) => {
    // console.log(data, "here is the data");

    return (
      <img
        src={data.productImage}
        alt={"productImage"}
        width="64px"
        className="shadow-4 rounded"
      />
    );
  };

  const header = renderHeader();

  return (
    <div className="card">
      <DataTable
        value={customers}
        paginator
        rows={10}
        dataKey="id"
        filters={filters}
        loading={loading}
        globalFilterFields={["name", "quantity"]}
        header={header}
        emptyMessage="No products found."
        tableStyle={{ minWidth: "60rem" }}
      >
        <Column
          field="productName"
          header="product Name"
          filter
          filterPlaceholder="Search by name"
        />
        <Column header="Image" body={imageBodyTemplate} />
        <Column
          field="isPaid"
          header="is Paid"
          body={statusBodyTemplate}
          filterMenuStyle={{ width: "14rem" }}
          filter
        />
        <Column
          field="isDelivered"
          header="is delivered"
          body={statusBodyTemplateIsDelivered}
          filterMenuStyle={{ width: "14rem" }}
          filter
        />
        <Column
          field="quantity"
          header="Quantity"
          dataType="numeric"
          filter
          sortable
          filterPlaceholder="Search by quantity"
        />
        <Column
          field="createdAt"
          header="Date"
          filterField="createdAt"
          dataType="date"
          body={dateBodyTemplate}
          filter
          filterElement={dateFilterTemplate}
        />
        <Column
          header="Price"
          filterField="price"
          dataType="numeric"
          body={balanceBodyTemplate}
          filter
          filterElement={balanceFilterTemplate}
          sortable
        />
        <Column
          header="Total order price"
          filterField="totalOrderPrice"
          field="totalOrderPrice"
          dataType="numeric"
          filter
          sortable
        />
      </DataTable>
    </div>
  );
}
