import React from "react";

const Counter = ({ quantity, setQuantity, limit }) => {
  const addOne = () => {
    if (quantity < limit) {
      setQuantity((prev) => ++prev);
    }
  };

  const removeOne = () => {
    if (quantity > 0) setQuantity((prev) => --prev);
  };
  return (
    <div className="cart-count float-end me-2">
      <div className="number">
        <span className="minus" onClick={removeOne}>
          -
        </span>
        <input
          type="text"
          className="open-font cart-input mx-1"
          value={quantity}
        />
        <span className="plus" onClick={addOne}>
          +
        </span>
      </div>
    </div>
  );
};

export default Counter;
