import React from "react";
import "primeicons/primeicons.css";
import { PrimeReactProvider } from "primereact/api";
import "primeflex/primeflex.css";
import "primereact/resources/primereact.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";

import "./index.css";
import "./flags.css";
import BasicFilterDemo from "./Tableindex";

const ProductsTable = ({ products }) => {
  return (
    <PrimeReactProvider>
      <BasicFilterDemo products={products} />
    </PrimeReactProvider>
  );
};

export default ProductsTable;
