import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../api";

const initialState = {
  carts: [],
  status: "initial",
  error: "null",
};

export const addToCartFunction = createAsyncThunk(
  "/add-to-cart",
  async ({ productId, quantity }) => {
    const response = await api.post("/cart", { _id: productId, quantity });
    return response;
  }
);

export const getCart = createAsyncThunk("/get-cart", async () => {
  const response = await api.get("/cart");

  return response;
});

export const updateItemQuantity = createAsyncThunk(
  "/update-quantity",
  async ({ itemId, quantity }) => {
    const response = await api.put(`/cart/${itemId}`, quantity);

    return response;
  }
);

export const removeProduct = createAsyncThunk(
  "/remove-product",
  async (itemId) => {
    const response = await api.delete(`/cart/${itemId}`);

    return response;
  }
);

export const cart = createSlice({
  name: "cart",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(addToCartFunction.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(addToCartFunction.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.carts = [...state.carts, action.payload.data.data];
      })
      .addCase(addToCartFunction.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(getCart.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.carts = [action.payload.data.data];
      })
      .addCase(getCart.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(removeProduct.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.carts = [action.payload.data.data];
      })
      .addCase(removeProduct.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default cart.reducer;
