import React, { useState } from "react";
import { Button } from "react-bootstrap";
import AddProduct from "./Modal/addProduct";

const Dashnav = ({ title }) => {
  const [show, setShow] = useState(false);
  return (
    <div
      className="das-nav md-mt-6 p-0 bg-current-shade bg-image-bottomcenter bg-image-cover"
      style={{
        backgroundImage: `url("https://via.placeholder.com/1900x300.png")`,
      }}
    >
      {title === "All Products" && <AddProduct show={show} setShow={setShow} />}
      <div className="container">
        <div className="row">
          <div className="row-lg-8 ps-4 offset-lg-4 d-flex align-items-start flex-row h-250">
            <h1 className="mt-lg-auto mb-4 display3-size display1-sm-size text-grey-900 fw-700">
              {title}
            </h1>
            <div className="row-lg-8 ps-4 offset-lg-4 d-flex align-items-start flex-row h-250">
              {title === "All Products" && (
                <Button
                  variant="success"
                  className="mt-lg-auto mb-4  "
                  size="lg"
                  onClick={() => setShow(true)}
                >
                  <span className="fs-50 text-black fw-700 m-2">+</span>
                  Add product
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashnav;
