import React, { useState, useEffect } from "react";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { Tag } from "primereact/tag";
import { Calendar } from "primereact/calendar";

export default function UsersTable({ users }) {
  const [customers, setCustomers] = useState();
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    userName: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    createdAt: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
    },
    verified: { value: null, matchMode: FilterMatchMode.EQUALS },
  });
  const [loading, setLoading] = useState(false);
  const [globalFilterValue, setGlobalFilterValue] = useState("");

  const getSeverity = (status) => {
    // eslint-disable-next-line default-case
    switch (status) {
      case true:
        return "success";

      case false:
        return "warning";
    }
  };

  /////////////////////////////////////////////////////////////////////////////////////////////

  const formatDate = (value) => {
    if (!value) return "N/A";

    return value.toLocaleDateString("en-US", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
  };

  const dateBodyTemplate = (rowData) => {
    return formatDate(rowData.createdAt);
  };

  const dateFilterTemplate = (options) => {
    return (
      <Calendar
        value={options.value}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        dateFormat="mm/dd/yy"
        placeholder="mm/dd/yyyy"
        mask="99/99/9999"
      />
    );
  };

  const statusBodyTemplate = (rowData) => {
    return (
      <Tag
        value={rowData.active ? "verified" : "not verified"}
        severity={getSeverity(rowData.active)}
      />
    );
  };

  useEffect(() => {
    setCustomers(getCustomers(users[0]));
    setLoading(false);
  }, [users]);

  const getCustomers = (data) => {
    if (!Array.isArray(data)) return [];

    return data.map((d) => {
      return {
        ...d, // Spread the original object
        createdAt: d.createdAt ? new Date(d.createdAt) : null, // Safely parse the date and assign it to a new object
      };
    });
  };

  if (!users || users.length === 0) {
    return <p>No data available</p>;
  }
  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const renderHeader = () => {
    return (
      <div className="flex justify-content-end">
        <IconField iconPosition="left">
          <InputIcon className="pi pi-search" />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Keyword Search"
          />
        </IconField>
      </div>
    );
  };

  const header = renderHeader();

  return (
    <div className="card">
      <DataTable
        value={customers}
        paginator
        rows={10}
        dataKey="id"
        filters={filters}
        loading={loading}
        globalFilterFields={["name", "status"]}
        header={header}
        emptyMessage="No users Found."
        tableStyle={{ minWidth: "20rem" }}
      >
        <Column
          field="name"
          header="user name"
          filter
          filterPlaceholder="Search by name"
        />
        <Column
          field="active"
          header="active"
          body={statusBodyTemplate}
          filterMenuStyle={{ width: "14rem" }}
          filter
        />
        <Column
          field="createdAt"
          header="Date of joining"
          filterField="createdAt"
          dataType="date"
          body={dateBodyTemplate}
          filter
          filterElement={dateFilterTemplate}
        />
      </DataTable>
    </div>
  );
}
