import React from "react";
import { Route, Redirect } from "react-router-dom";

const ProtectedRoute = ({
  component: Component,
  requireActivation = false,
  ...rest
}) => {
  const user = JSON.parse(localStorage.getItem("user") || "{}");

  const isAuthenticated = !!user;
  const isActivate = requireActivation ? user.active : true;

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated && isActivate ? (
          <Component {...props} />
        ) : (
          <Redirect to={`${process.env.PUBLIC_URL}/logintwo`} />
        )
      }
    />
  );
};

export default ProtectedRoute;
