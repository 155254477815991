import React, { Fragment, useRef, useState } from "react";
import { Formik } from "formik";
import axios from "axios";
import TextField from "../components/forms/TextField";
import { validationLogInSchema } from "../helper/validation";
import { Loader } from "../components/Loader";
import { Toast } from "primereact/toast";
import PRO2 from "../../src/PRO2.png" 

const initialValues = {
  email: "",
  password: "",
};

const LogInTwo = () => {
  const [loader, setLoader] = useState(false);
  const toast = useRef(null);

  const onSubmit = (values) => {
    setLoader(true);
    axios
      .post("https://770d-178-130-149-192.ngrok-free.app/api/v1/auth/login", values)
      .then((res) => {
        setLoader(false);
        console.log(res, "here is the response");
        localStorage.setItem("user", JSON.stringify(res.data));
        window.location.href = "/dashboard";
      })
      .catch((err) => {
        console.log(err.response.data.message, "here is the error");
        toast.current.show({
          severity: "error",
          summary: "Product Expanded",
          detail: err.response.data.message,
          life: 10000,
        });
        setLoader(false);
      });
  };

  return (
    <Fragment>
      <Toast ref={toast} />
      <div className="main-wrap min-vh-100">
        <div className="row min-vh-100">
          <div className="card shadow-lg border-0 ms-auto me-auto login-card mt-50 mb-auto zindex-100 rounded-10 theme-dark-bg">
            <div className="card-body rounded-0 text-start">
              <h2 className="fw-700 display1-size display2-md-size mb-4 mt-2 white-text text-grey-900">
                Login into <br />
                your account
              </h2>
              <Formik
                validationSchema={validationLogInSchema}
                initialValues={initialValues}
                onSubmit={onSubmit}
              >
                {({ handleSubmit, values, setFieldValue }) => (
                  <form onSubmit={handleSubmit}>
                    <Loader show={loader} />
                    <div className="form-group icon-input mb-3">
                      <i className="font-sm ti-email text-grey-500 pe-0"></i>
                      <TextField
                        name="email"
                        type="text"
                        className="style2-input ps-5 form-control text-grey-900 white-text font-xsss fw-600"
                        placeholder="Email Address"
                      />
                    </div>
                    <div className="form-group icon-input mb-1">
                      <TextField
                        name="password"
                        type="Password"
                        className="style2-input ps-5 form-control text-grey-900 white-text font-xss ls-3"
                        placeholder="Password"
                      />
                      <i className="font-sm ti-lock text-grey-500 pe-0"></i>
                    </div>
                    <div className="form-check text-start mb-3">
                      <a
                        href="/forgotone"
                        className="fw-600 font-xssss text-grey-700 white-text mt-1 float-right"
                      >
                        Forgot your Password?
                      </a>
                    </div>
                    <div className="col-sm-12 p-0 text-start">
                      <div className="form-group mb-1">
                        <button
                          Link="/da"
                          type="submit"
                          className="bg-current text-center style2-input text-white fw-600 border-0 p-0 w-100"
                        >
                          Login
                        </button>
                      </div>
                      <h6 className="text-grey-500 font-xssss fw-500 mt-0 mb-0 lh-32">
                        Dont have account{" "}
                        <a href="/registerTwo" className="fw-700 ms-1">
                          Register
                        </a>
                      </h6>
                    </div>
                  </form>
                )}
              </Formik>

              <div className="col-sm-12 p-0 text-center mt-2 mb-3">
                <h6 className="mb-0 d-inline-block fw-500 font-xssss text-grey-500 mb-3">
                  Or, Sign in with your social account{" "}
                </h6>
                <div className="form-group mb-1">
                  <a
                    href="/"
                    className="text-start font-xsss style2-input text-grey-900 fw-600 bg-greylight border-0 p-0 mb-2"
                  >
                    <img
                      src={PRO2}
                      alt="icon"
                      className="ms-2 w-40 mb-1 me-5"
                    />{" "}
                    Sign in with Google
                  </a>
                </div>
                <div className="form-group mb-1">
                  <a
                    href="/"
                    className="text-start font-xsss style2-input text-white fw-600 bg-twiiter border-0 p-0 "
                  >
                    <img
                      src="assets/images/icon-3.png"
                      alt="icon"
                      className="ms-2 w-40 mb-1 me-5"
                    />{" "}
                    Sign in with Facebook
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default LogInTwo;
