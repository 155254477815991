import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../api";
const initialState = {
  product: {},
  status: "initial",
  error: "null",
};

export const addProductFunction = createAsyncThunk(
  "/add-product",
  async (body) => {
    const response = await api.post("/products/service", body);
    return response;
  }
);

export const addProduct = createSlice({
  name: "addProduct",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(addProductFunction.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(addProductFunction.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.product = action.payload.data.data;
      })
      .addCase(addProductFunction.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default addProduct.reducer;
