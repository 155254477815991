import React from "react";
import { useField } from "formik";
import classNames from "classnames";
import { ControlWrapper } from "./ControlWrapper";

const TextField = ({ name, type, placeholder, className }) => {
  const [field, meta] = useField(name);
  const isError = meta.error && meta.touched;
  return (
    <ControlWrapper name={name}>
      <input
        {...field}
        name={name}
        type={type}
        className={classNames(className, {
          "input-error": isError,
        })}
        placeholder={placeholder}
      />
    </ControlWrapper>
  );
};

export default TextField;
