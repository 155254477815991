import { io } from "socket.io-client";

const socket = io("https://770d-178-130-149-192.ngrok-free.app ", {
  transports: ["websocket", "polling"], // Ensure proper transport
});

socket.on("connect", () => {
  console.log("Connected to Socket.IO Server:", socket.id);
});

export default socket;
