import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Slider from "react-slick";
import {
  getAllProductsGeneral,
  getProductsUser,
} from "../redux/products/getProducts";

const discountProduct = [
  {
    imageUrl: "p.png",
    title: "Assorted Donuts Salted ",
    price: "59",
    weight: "300 gm",
  },
];

const Test = () => {
  const discountProductsettings = {
    arrows: true,
    dots: false,
    infinite: false,
    speed: 300,
    slidesToShow: 4,
    centerMode: false,
    responsive: [
      {
        breakpoint: 749,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 0,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  // Redux
  const dispatch = useDispatch();
  const products = useSelector((state) => state.getProducts.products);

  useEffect(() => {
    dispatch(getAllProductsGeneral());
  }, [dispatch]);

  const renderStars = useCallback(
    () => (
      <div className="star d-inline text-left">
        {Array(5)
          .fill(0)
          .map((_, i) => (
            <img
              key={i}
              src="assets/images/star.png"
              alt="star"
              className="w-1 me-1 float-start"
            />
          ))}
      </div>
    ),
    []
  );

  return (
    <div className="col-lg-12">
      <div className="card border-0">
        <Slider {...discountProductsettings} className="slick-arrow-top">
          {products.map((value, index) => (
            <div key={index} className="p-3 posr">
              <h4 className="ls-3 font-xsssss text-white text-uppercase bg-current fw-700 p-2 d-inline-block posa rounded-3">
                30% off
              </h4>
              <span className="posa right-0 top-0 mt-3 me-3 z-index-5">
                <i className="ti-heart font-xs text-grey-500"></i>
              </span>
              <div className="clearfix"></div>
              <a
                href={`/single-product/${value._id}`}
                className="d-block text-center p-2"
              >
                <img
                  src={value.productImages[0]}
                  alt="product"
                  className="w-100 mt-1 d-inline-block"
                  style={{ height: "140px" }}
                />
              </a>

              {renderStars()}
              <div className="clearfix"></div>
              <h2 className="mt-1">
                <a
                  href="/single-product-1"
                  className="text-grey-700 fw-600 font-xsss lh-2 ls-0"
                >
                  {value.productName}
                </a>
              </h2>
              <h6 className="font-xss ls-3 fw-700 text-current d-flex">
                <span className="font-xsssss text-grey-500">$</span>
                {value.price}{" "}
                <span className="ms-auto me-4 text-grey-500 fw-500 font-xsssss">
                  {value.weight}
                </span>
              </h6>
              <div className="cart-count d-flex mt-4 mb-2">
                <div className="number">
                  <span className="minus">-</span>
                  <input
                    type="text"
                    className="open-font me-1 ms-1"
                    defaultValue="1"
                  />
                  <span className="plus">+</span>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default React.memo(Test);
