import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../api";

const initialState = {
  orders: [],
  status: "initial",
  error: "null",
  lastFetched: {
    serviceProviders: null,
    restaurants: null,
    products: null,
    orders: null,
  },
};

const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes

export const createCashOrder = createAsyncThunk(
  "/create-order",
  async ({ cartId, shippingAddress, from }) => {
    const response = await api.post(`/orders/${cartId}`, {
      shippingAddress,
      from,
    });

    return response;
  }
);

export const getAllOrdersUser = createAsyncThunk(
  "/get-order-user",
  async (userId) => {
    const response = await api.get(`/orders/${userId}`);

    return response;
  }
);

export const getAllOrdersProduct = createAsyncThunk(
  "/get-order-product",
  async (productId) => {
    const response = await api.get(`/orders/${productId}`);

    return response;
  }
);

export const getAllOrdersAdmin = createAsyncThunk(
  "/get-orders-admin",
  async (_, thunkAPI) => {
    const state = thunkAPI.getState();
    const lastFetched = state.admin.lastFetched.orders;
    const currentTime = Date.now();

    if (state.admin.orders > 0 && currentTime - lastFetched < CACHE_DURATION) {
      return thunkAPI.rejectWithValue("Cached data exists");
    }

    const response = await api.get(`/users/orders`);

    return response;
  }
);

export const order = createSlice({
  name: "order",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(createCashOrder.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(createCashOrder.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.orders = [action.payload.data.data];
      })
      .addCase(createCashOrder.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(getAllOrdersUser.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.orders = [...action.payload.data];
      })
      .addCase(getAllOrdersUser.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(getAllOrdersProduct.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.orders = [...action.payload.data];
      })
      .addCase(getAllOrdersProduct.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(getAllOrdersAdmin.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getAllOrdersAdmin.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.orders = action.payload.data.data;
        state.lastFetched.orders = Date.now();
      })
      .addCase(getAllOrdersAdmin.rejected, (state, action) => {
        if (action.payload === "Cached data exists") {
          state.status = "succeeded"; // Treat as succeeded since cached data is used
        } else {
          state.status = "failed";
          state.error = action.error.message;
        }
      });
  },
});

export default order.reducer;
