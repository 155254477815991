import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "./style.css";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const ModalInst = (props) => {
  const history = useHistory();
  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" className="">
            Hello Mohammed..
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Hi, we are pleased to welcome you at ....... Our aim is to
            help..................................................
            ........................................................
          </p>
          <ul className="py-2 list-disc pl-3">
            <li className="py-2">
              Please, before start your own work, please complete your profile.
              It is Important to provide us with required information!!
            </li>
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              props.onHide();
              history.push("/address");
            }}
          >
            Complete Profile
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalInst;
