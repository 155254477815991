import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../api";

const initialState = {
  products: [],
  discountProducts: [],
  pointedProduct: {},
  status: "initial",
  error: "null",
  lastFetched: {
    serviceProviders: null,
    restaurants: null,
    products: null,
    orders: null,
  },
};

const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes

export const getAllProductsAdmin = createAsyncThunk(
  "/get-products-admin",
  async (_, thunkAPI) => {
    const state = thunkAPI.getState();
    const lastFetched = state.admin.lastFetched.products;
    const currentTime = Date.now();

    if (
      state.admin.products > 0 &&
      currentTime - lastFetched < CACHE_DURATION
    ) {
      return thunkAPI.rejectWithValue("Cached data exists");
    }
    const response = await api.get(`/users/products`);

    return response;
  }
);

export const getProductsUser = createAsyncThunk(
  "/get-products",
  async ({ userId }) => {
    const response = await api.get(`/products/service`);
    return response;
  }
);

export const getAllProductsGeneral = createAsyncThunk(
  "/get-products-general",
  async () => {
    const response = await api.get(`/products`);

    return response;
  }
);

export const getDiscountProducts = createAsyncThunk(
  "/get-products-discount",
  async () => {
    const filterObj = {
      discount: { $ne: null },
    };

    const response = await api.get(
      `/products/filter/${JSON.stringify(filterObj)}`
    );

    return response;
  }
);

export const getPointedProduct = createAsyncThunk(
  "/get-pointedProduct",
  async (productId) => {
    const response = await api.get(`/products/${productId}`);

    return response;
  }
);

export const getProducts = createSlice({
  name: "getProducts",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getProductsUser.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getProductsUser.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.products = action.payload.data.data;
      })
      .addCase(getProductsUser.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload || action.error.message;
      })
      .addCase(getAllProductsGeneral.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getAllProductsGeneral.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.products = action.payload.data.data;
      })
      .addCase(getAllProductsGeneral.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload || action.error.message;
      })
      .addCase(getDiscountProducts.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getDiscountProducts.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.discountProducts = action.payload.data.data;
      })
      .addCase(getDiscountProducts.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload || action.error.message;
      })
      .addCase(getPointedProduct.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.pointedProduct = action.payload.data.data;
      })
      .addCase(getPointedProduct.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload || action.error.message;
      })
      .addCase(getAllProductsAdmin.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getAllProductsAdmin.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.products = action.payload.data.data;
        state.lastFetched.products = Date.now();
      })
      .addCase(getAllProductsAdmin.rejected, (state, action) => {
        if (action.payload === "Cached data exists") {
          state.status = "succeeded"; // Treat as succeeded since cached data is used
        } else {
          state.status = "failed";
          state.error = action.error.message;
        }
      });
  },
});

// Export the action creators
export const { updatePointedProduct } = getProducts.actions;

export default getProducts.reducer;
