import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  message: null,
  severity: null,
};

const toastSlice = createSlice({
  name: "toast",
  initialState,
  reducers: {
    showToast: (state, action) => {
      state.message = action.payload.message;
      state.severity = action.payload.severity;
    },
    clearToast: (state) => {
      state.message = null;
      state.severity = null;
    },
  },
});

export const { showToast, clearToast } = toastSlice.actions;
export default toastSlice.reducer;
