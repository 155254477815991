import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../api";

const initialState = {
  info: {},
  status: "initial",
  error: null,
};

export const provideServiceInfo = createAsyncThunk(
  "salers/addInfo",
  async (body) => {
    console.log(body, "5555555555555555Here is the body");

    const response = await api.post("/serviceProvider", body);

    return response.data.data;
  }
);

export const provideRestaurantInfo = createAsyncThunk(
  "restaurant/addInfo",
  async (body) => {
    body.userId = JSON.parse(localStorage.getItem("user")).data._id;

    const response = await api.post("/Restaurant", body);

    return response.data.data;
  }
);
export const getSalersInfo = createAsyncThunk("salers/Info", async () => {
  const userId = JSON.parse(localStorage.getItem("user")).data._id;

  const response = await api.get(`/serviceProvider/${userId}`);

  return response;
});
export const getRestaurantInfo = createAsyncThunk(
  "restaurant/Info",
  async (userId) => {
    const response = await api.get(`/restaurant/${userId}`);

    return response;
  }
);

export const userInfo = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(provideServiceInfo.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(provideServiceInfo.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.info = action.payload;
      })
      .addCase(provideServiceInfo.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(provideRestaurantInfo.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(provideRestaurantInfo.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.info = action.payload;
      })
      .addCase(provideRestaurantInfo.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(getSalersInfo.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getSalersInfo.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.info = action.payload.data.data;
      })
      .addCase(getSalersInfo.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default userInfo.reducer;
