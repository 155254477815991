import React,{Component} from 'react';

class Addbannerthree extends Component {
    render() {
        return (
            <div className="card w-100 border-0 shadow-none mb-3 ovh rounded-6 hover-zoom-image">
                <img src="https://via.placeholder.com/260x420.png" alt="banner" className="w-100" />
                <div className="p-4 posa bottom-0 w-100">
                    <span className="fw-700 ls-3 text-white bg-current ps-2 pe-2 lh-24 rounded-6 d-inline-block font-xsssss">30% OFF</span>
                    <h4 className="font-xs fw-700 lh-28 text-grey-900 mb-3 mt-3 ls-0">High Quality <br /> Products</h4>
                    <a href="/g-1" className="fw-700 ls-3 text-grey-900 font-xsssss">SHOP NOW</a>
                </div>  
            </div>
        );
    }
}

export default Addbannerthree;