import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import Homeone from "./pages/Homeone";

import Shopone from "./pages/Shopone";
import Shoptwo from "./pages/Shoptwo";
import Shopthree from "./pages/Shopthree";
import Shopfour from "./pages/Shopfour";
import Shopfive from "./pages/Shopfive";
import Notfound from "./pages/Notfound";
import About from "./pages/About";
import Blog from "./pages/Blog";
import Contact from "./pages/Contact";
import Faq from "./pages/Faq";
import Blogsingle from "./pages/Blogsingle";

import Logintwo from "./pages/Logintwo";
import Registertwo from "./pages/Registertwo";
import Forgotone from "./pages/Forgotone";
import Verifyone from "./pages/Verifyone";

import Dashboard from "./pages/Dashboard";
import Order from "./pages/Order";
import Payment from "./pages/Payment";
import Address from "./pages/address/index";
import Notification from "./pages/Notification";

import Saved from "./pages/Saved";
import Cart from "./pages/Cart";
import Checkout from "./pages/Checkout";

import Singleone from "./pages/Singleone";
import Products from "./pages/products";

import ProtectedRoute from "./protectedRoute";
import Test from "./pages/test";
import ProductsTable from "./pages/Table/productTable";
import Category from "./pages/Category";

const App = () => {
  return (
    <div>
      <BrowserRouter>
        <Switch>
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/`}
            component={Homeone}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/g-1`}
            component={Test}
          />

          {/* //////////////////////////////////////////  */}

          <ProtectedRoute
            exact
            path={`${process.env.PUBLIC_URL}/table`}
            component={ProductsTable}
            requireActivation={true}
          />

          {/* //////////////////////////////////////////////// */}

          <ProtectedRoute
            exact
            path={`${process.env.PUBLIC_URL}/saved`}
            component={Saved}
          />
          <ProtectedRoute
            exact
            path={`${process.env.PUBLIC_URL}/cart`}
            component={Cart}
          />
          <ProtectedRoute
            exact
            path={`${process.env.PUBLIC_URL}/category`}
            component={Category}
          />
          <ProtectedRoute
            exact
            path={`${process.env.PUBLIC_URL}/checkout`}
            component={Checkout}
          />
          <ProtectedRoute
            exact
            path={`${process.env.PUBLIC_URL}/shop-1`}
            component={Shopone}
          />
          <ProtectedRoute
            exact
            path={`${process.env.PUBLIC_URL}/shop-2`}
            component={Shoptwo}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/shop-3`}
            component={Shopthree}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/shop-4`}
            component={Shopfour}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/shop-5`}
            component={Shopfive}
          />

          <ProtectedRoute
            exact
            path={`${process.env.PUBLIC_URL}/single-product/:id`}
            component={Singleone}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/about`}
            component={About}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/blog`}
            component={Blog}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/contact`}
            component={Contact}
          />
          <Route exact path={`${process.env.PUBLIC_URL}/faq`} component={Faq} />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/blog-single`}
            component={Blogsingle}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/notfound`}
            component={Notfound}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/logintwo`}
            component={Logintwo}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/registertwo`}
            component={Registertwo}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/forgotone`}
            component={Forgotone}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/verifyone`}
            component={Verifyone}
          />
          <ProtectedRoute
            exact
            path={`${process.env.PUBLIC_URL}/dashboard`}
            component={Dashboard}
          />
          <ProtectedRoute
            exact
            path={`${process.env.PUBLIC_URL}/orders`}
            component={Order}
          />
          <ProtectedRoute
            exact
            path={`${process.env.PUBLIC_URL}/payment`}
            component={Payment}
          />
          <ProtectedRoute
            exact
            path={`${process.env.PUBLIC_URL}/products`}
            component={Products}
          />
          <ProtectedRoute
            exact
            path={`${process.env.PUBLIC_URL}/address`}
            component={Address}
          />
          <ProtectedRoute
            exact
            path={`${process.env.PUBLIC_URL}/notification`}
            component={Notification}
          />
        </Switch>
      </BrowserRouter>
    </div>
  );
};

export default App;
