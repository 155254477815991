import React, { useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { useSelector } from "react-redux";

const ToastManager = () => {
  const toast = useRef();
  const { message, severity } = useSelector((state) => state.toast);

  useEffect(() => {
    if (message) {
      toast.current.show({
        severity: severity || "info",
        summary: message,
        life: 10000,
      });
    }
  }, [message, severity]);

  return <Toast ref={toast} />;
};

export default ToastManager;
