import React, { Fragment, useEffect } from "react";
import TextField from "../components/forms/TextField";
import Header from "../components/Header";
import Headermob from "../components/Headermob";
import Upperheader from "../components/Upperheader";
import Lowerheader from "../components/Lowerheader";
import Footer from "../components/Footer";
import Pagetitle from "../components/Pagetitle";
import { Formik } from "formik";
import { validationShippingSchema } from "../helper/validation";
import { useDispatch, useSelector } from "react-redux";
import { createCashOrder } from "../redux/order";
import { getCart } from "../redux/Cart";

const Checkout = () => {
  // User
  const user = JSON.parse(localStorage.getItem("user")).data;

  // Redux
  const dispatch = useDispatch();
  const { carts: cart } = useSelector((state) => state.cart);

  useEffect(() => {
    dispatch(getCart());
  }, [dispatch]);

  const initialValues = {
    name: user?.name,
    email: user?.email,
    phoneNumber: user?.phoneNumber,
    address: "",
    city: "",
  };

  console.log(cart, "here is the cart");

  const onSubmit = async (values) => {
    dispatch(
      createCashOrder({ shippingAddress: values, cartId: cart[0]?._id })
    );
  };

  return (
    <Fragment>
      <Headermob />
      <Upperheader divClass="bg-lightgrey" />
      <Header />
      <Lowerheader />

      <Pagetitle title="Checkout" />
      <Formik
        validationSchema={validationShippingSchema}
        initialValues={initialValues}
        onSubmit={onSubmit}
      >
        {({ handleSubmit, values }) => (
          <form onSubmit={handleSubmit}>
            <div className="content-wrap pt-lg-5 pb-lg-5 py-4 my-lg-3">
              <div className="container">
                <div className="row">
                  <div className="col-lg-8 checkout-form mb-3">
                    <div className="card border-size-md border p-4">
                      <div className="row">
                        <div className="col-lg-12">
                          <h6 className="font-xsss ls-3 fw-700 text-grey-700 border-bottom-light lh-38 mb-3">
                            BILLING DETAILS
                          </h6>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group">
                            <label htmlFor="Name">Name</label>
                            <TextField
                              type="text"
                              className="form-control bg-greylight border-0"
                              placeholder="Enter your name"
                              id="Name"
                              name={"name"}
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="Email">Email</label>
                            <TextField
                              type="text"
                              className="form-control bg-greylight border-0"
                              placeholder="Enter your email"
                              id="Email"
                              name="email"
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="Phone">Phone</label>
                            <TextField
                              type="text"
                              className="form-control bg-greylight border-0"
                              placeholder="Enter your phone"
                              id="Phone"
                              name="phoneNumber"
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="Address">Address</label>
                            <TextField
                              type="text"
                              className="form-control bg-greylight border-0"
                              placeholder="Enter your address"
                              id="Address"
                              name="address"
                            />
                          </div>
                        </div>

                        <div className="col-lg-12">
                          <div className="form-group">
                            <label htmlFor="City">City</label>
                            <TextField
                              type="text"
                              className="form-control bg-greylight border-0"
                              placeholder="Enter here"
                              id="City"
                              name="city"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-4 checkout-form">
                    <div className="card border-size-md border border-danger p-4">
                      <div className="row">
                        <div className="col-lg-12">
                          <h6 className="font-xsss ls-3 fw-700 text-grey-700 border-bottom-light lh-38 mb-3">
                            YOUR ORDERS
                          </h6>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-12">
                          <h4 className="text-grey-900 font-xssss fw-600 mb-2 d-flex">
                            Subtotal{" "}
                            <span className="ms-auto text-grey-500">
                              $ {cart[0]?.totalCartPrice}
                            </span>
                          </h4>
                          <h4 className="text-grey-900 font-xssss fw-600 mb-3 d-flex">
                            Tax{" "}
                            <span className="ms-auto text-grey-500">$ 0</span>
                          </h4>
                          <h4 className="text-grey-900 font-xss fw-600 mb-3 d-flex">
                            Total{" "}
                            <span className="ms-auto">
                              $ {cart[0]?.totalCartPrice}
                            </span>
                          </h4>
                          <h5 className="bg-greylight p-4 rounded-6 mt-3 mb-3 w-100 fw-600 text-grey-500 font-xssss d-flex">
                            Apply Promo Code :{" "}
                            <span className="ms-auto fw-700 text-grey-900">
                              2 Promos
                            </span>
                          </h5>
                        </div>
                        <div className="col-lg-12">
                          <h6 className="font-xsss ls-3 fw-700 text-grey-700 border-bottom-light lh-38 mb-3">
                            PAYMENT
                          </h6>
                        </div>
                        <div className="col-lg-12 mt-3">
                          <button className="w-100 text-white rounded-6 text-center btn fw-700 ls-3 font-xssss text-uppercase btn-primary">
                            Choose payment method
                          </button>
                        </div>

                        <div className="col-lg-12 mt-3">
                          <button
                            type="submit"
                            className="w-100 bg-current text-white rounded-6 text-center btn fw-700 ls-3 font-xssss text-uppercase"
                          >
                            PLACE order
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        )}
      </Formik>
      <Footer />
    </Fragment>
  );
};

export default Checkout;
