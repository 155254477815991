import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../api";

const initialState = {
  subCategories: [],
  status: "initial",
  error: "null",
};

export const getSubCategoryFunc = createAsyncThunk(
  "/get-subCategory",
  async () => {
    const response = await api.get("/subcategories");

    return response;
  }
);

export const addSubCategoryAdmin = createAsyncThunk(
  "/add-subCategory-admin",
  async (body) => {
    const response = await api.post("/subcategories", body);

    return response;
  }
);

export const subCategory = createSlice({
  name: "subCategory",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(addSubCategoryAdmin.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(addSubCategoryAdmin.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.subCategories = [
          ...state.subCategories,
          action.payload.data.data,
        ];
      })
      .addCase(addSubCategoryAdmin.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(getSubCategoryFunc.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getSubCategoryFunc.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.subCategories = action.payload.data.data;
      })
      .addCase(getSubCategoryFunc.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default subCategory.reducer;
