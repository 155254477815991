import React, { Component } from "react";
import { Link } from "react-router-dom";

class Lowerheader extends Component {
  constructor() {
    super();
    this.state = {
      location: false,
    };
  }
  handleModel() {
    this.setState({ location: !this.state.location });
  }

  render() {
    return (
      <div className="lower-header pt-0 pb-0 shadow-xss bg-invert bg-deepgreen d-none d-lg-block">
        <div className="container">
          <div className="container-wrap posr">
            <div className="row">
              <div className="col-lg-3 pe-0">
                <div className="dropdown dropdown-right border-0"></div>
              </div>
              <div className="col-lg-9">
                <div className="navbar navbar-expand-lg p-0">
                  <div className="navbar-collapse collapse show" id="main_nav">
                    <button
                      className="navbar-toggler "
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#main_nav"
                      aria-expanded="true"
                      aria-label="Toggle navigation"
                    >
                      <span className="navbar-toggler-icon"></span>
                    </button>
                    <ul className="navbar-nav">
                      {/* <li className="nav-item nav-item-toggle site-menu">
                        <a className="nav-link dropdown-toggle" href="/">
                          Home
                        </a>
                      </li> */}

                      {/* <li className="nav-item nav-item-toggle mega-menu">
                        <a className="nav-link dropdown-toggle" href="/">
                          Pages
                        </a>
                        <ul className="sub-menu">
                          <li>
                            <a href="/">Shop Default</a>
                            <ul className="sub-mega-menu">
                              <li>
                                <Link to="/shop-1"> Shop One</Link>
                              </li>
                              <li>
                                <Link to="/shop-2"> Shop Two </Link>
                              </li>
                              <li>
                                <Link to="/shop-3"> Shop Three </Link>
                              </li>
                              <li>
                                <Link to="/shop-4"> Shop Four</Link>
                              </li>
                              <li>
                                <Link to="/shop-5"> Shop Five </Link>
                              </li>
                            </ul>
                          </li>
                          <li>
                            <a href="/">Shop Page</a>
                            <ul className="sub-mega-menu">
                              <li>
                                <Link to="/cart">Cart</Link>
                              </li>
                              <li>
                                <Link to="/checkout">Checkout</Link>
                              </li>
                              <li>
                                <Link to="/dashboard">My account</Link>
                              </li>
                              <li>
                                <Link to="/saved">Wishlist</Link>
                              </li>
                              <li>
                                <Link to="/orders">Order Tracking</Link>
                              </li>
                            </ul>
                          </li>
                          <li>
                            <a href="/">Product Page</a>
                            <ul className="sub-mega-menu">
                              <li>
                                <Link to="/single-product-1"> Single One</Link>
                              </li>
                              <li>
                                <Link to="/single-product-2"> Single Two </Link>
                              </li>
                              <li>
                                <Link to="/single-product-3">
                                  {" "}
                                  Single Three{" "}
                                </Link>
                              </li>
                              <li>
                                <Link to="/single-product-4"> Single Four</Link>
                              </li>
                              <li>
                                <Link to="/single-product-5"> Single Five</Link>
                              </li>
                            </ul>
                          </li>
                          <li>
                            <a href="/">Authentication</a>
                            <ul className="sub-mega-menu">
                              <li>
                                <Link to="/logintwo">Login Page</Link>
                              </li>
                              <li>
                                <Link to="/registertwo">Register Page</Link>
                              </li>
                              <li>
                                <Link to="/forgottwo">Forgot Password</Link>
                              </li>
                              <li>
                                <Link to="/verifytwo">Veryfy Page</Link>
                              </li>
                              <li>
                                <Link to="/notfound">404 Page</Link>
                              </li>
                            </ul>
                          </li>
                          <li>
                            <a href="/">Other Pages</a>
                            <ul className="sub-mega-menu">
                              <li>
                                <Link to="/about"> About Pages</Link>
                              </li>
                              <li>
                                <Link to="/contact"> Contact Pages</Link>
                              </li>
                              <li>
                                <Link to="/blog"> Blog Pages</Link>
                              </li>
                              <li>
                                <Link to="/blog-single"> Single Blog</Link>
                              </li>
                              <li>
                                <Link to="/faq"> Faq Pages</Link>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </li> */}
                      {/* <li className="nav-item nav-item-toggle site-menu">
                        <a
                          className="nav-link dropdown-toggle"
                          href="/dashboard"
                        >
                          Dashboard
                        </a>
                      </li> */}
                      {/* <li className="nav-item nav-item-toggle">
                        <a className="nav-items text-white" href="/blog">
                          Blog
                        </a>
                      </li> */}
                      {/* <li className="nav-item nav-item-toggle">
                        <a className="nav-items text-white" href="/contact">
                          Contact
                        </a>
                      </li> */}
                    </ul>
                    <ul className="navbar-nav ms-auto">
                      <li className="nav-item text-grey-100 fw-500 font-xssss">
                        Need help? Call Us :{" "}
                        <a href="tel:03340005000" className="fw-700 text-white">
                          + 033 4000 5000
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Lowerheader;
