import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../api";

const initialState = {
  serviceProviders: [],
  restaurants: [],
  orders: [],
  notifications: [],
  lastFetched: {
    serviceProviders: null,
    restaurants: null,
    products: null,
    orders: null,
  },
  status: "initial",
  error: "null",
};

const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes

export const getAllServiceProviders = createAsyncThunk(
  "/get-service",
  async (_, thunkAPI) => {
    const state = thunkAPI.getState();
    const lastFetched = state.admin.lastFetched.serviceProviders;
    const currentTime = Date.now();

    // If data exists and was fetched within the last 5 minutes, use cached data
    if (
      state.admin.serviceProviders.length > 0 &&
      currentTime - lastFetched < CACHE_DURATION
    ) {
      return thunkAPI.rejectWithValue("Cached data exists");
    }

    const response = await api.get(`/users/service`);
    return response;
  }
);

export const getAllRestaurant = createAsyncThunk(
  "/get-restaurant",
  async (_, thunkAPI) => {
    const state = thunkAPI.getState();
    const lastFetched = state.admin.lastFetched.restaurants;
    const currentTime = Date.now();

    if (
      state.admin.restaurants > 0 &&
      currentTime - lastFetched < CACHE_DURATION
    ) {
      return thunkAPI.rejectWithValue("Cached data exists");
    }
    const response = await api.get(`/users/restaurant`);

    return response;
  }
);

export const getNotificationsAdmin = createAsyncThunk(
  "/get-notifications",
  async () => {
    const response = await api.get(`/users/notification`);

    return response;
  }
);

export const activateUser = createAsyncThunk(
  "/user-activate",
  async (serviceId) => {
    const response = await api.put(`/users/activate/${serviceId}`);

    return response;
  }
);

export const setRead = createAsyncThunk("/set-Read", async (notificationId) => {
  const response = await api.put(`/users/read/${notificationId}`);

  return response;
});

export const admin = createSlice({
  name: "admin",
  initialState,
  reducers: {
    addNotification: (state, action) => {
      state.notifications.push(action.payload);
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getAllServiceProviders.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getAllServiceProviders.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.serviceProviders = [action.payload.data];
        state.lastFetched.serviceProviders = Date.now();
      })
      .addCase(getAllServiceProviders.rejected, (state, action) => {
        if (action.payload === "Cached data exists") {
          state.status = "succeeded"; // Treat as succeeded since cached data is used
        } else {
          state.status = "failed";
          state.error = action.error.message;
        }
      })
      .addCase(getAllRestaurant.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getAllRestaurant.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.restaurants = [action.payload.data];
        state.lastFetched.restaurants = Date.now();
      })
      .addCase(getAllRestaurant.rejected, (state, action) => {
        if (action.payload === "Cached data exists") {
          state.status = "succeeded"; // Treat as succeeded since cached data is used
        } else {
          state.status = "failed";
          state.error = action.error.message;
        }
      })
      .addCase(getNotificationsAdmin.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getNotificationsAdmin.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.notifications = action.payload.data.data;
      })
      .addCase(getNotificationsAdmin.rejected, (state, action) => {
        if (action.payload === "Cached data exists") {
          state.status = "succeeded"; // Treat as succeeded since cached data is used
        } else {
          state.status = "failed";
          state.error = action.error.message;
        }
      })
      .addCase(activateUser.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(setRead.fulfilled, (state, action) => {
        state.status = "succeeded";
      });
  },
});

export const { addNotification } = admin.actions;

export default admin.reducer;
