import React, { Fragment, useEffect, useState } from "react";

import Header from "../components/Header";
import Headermob from "../components/Headermob";
import Upperheader from "../components/Upperheader";
import Lowerheader from "../components/Lowerheader";
import Footer from "../components/Footer";
import Dashnav from "../components/Dashnav";
import Dashmenu from "../components/Dashmenu";
import Orders from "./Table/orders";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "../components/Loader";
import { getAllOrdersAdmin, getAllOrdersUser } from "../redux/order";

const Order = () => {
  //state
  const [loader, setLoader] = useState(false);

  // User
  const user = JSON.parse(localStorage.getItem("user"));

  // Redux
  const dispatch = useDispatch();
  const orders = useSelector((state) => state.order.orders);

  useEffect(() => {
    setLoader(true);

    const fetchOrders = async () => {
      try {
        if (user.data.role === "Admin") {
          await dispatch(getAllOrdersAdmin()).unwrap();
        } else {
          await dispatch(getAllOrdersUser(user.data._id)).unwrap();
        }
      } catch (error) {
        console.error("Error fetching orders:", error);
      } finally {
        setLoader(false); // Stop loader after fetching
      }
    };

    fetchOrders();
  }, [dispatch, user.data._id, user.data.role]);

  return (
    <Fragment>
      <Headermob />
      <Upperheader />
      <Header />
      <Lowerheader />
      <Dashnav title="Order" />

      <div className="main-div pb-5">
        <div className="container" style={{ maxWidth: "1450px" }}>
          <div className="row">
            <div className="col-lg-4" style={{ width: "25%" }}>
              <Dashmenu />
            </div>
            <div className="col-lg-8 pt-5 ps-4" style={{ width: "75%" }}>
              <Loader show={loader} />
              {!loader && orders?.length > 0 ? (
                <Orders orders={orders} />
              ) : (
                !loader && <p>No orders available.</p>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
};

export default Order;
