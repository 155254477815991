import { showToast } from "./index";

const toastMiddleware = (storeAPI) => (next) => (action) => {
  if (action.type.endsWith("/pending")) {
    storeAPI.dispatch(showToast({ message: "Loading...", severity: "info" }));
  }
  if (action.type.endsWith("/fulfilled")) {
    storeAPI.dispatch(
      showToast({ message: "Operation succeeded!", severity: "success" })
    );
  }

  if (action.type.endsWith("/rejected")) {
    storeAPI.dispatch(
      showToast({
        message: action.error.message || "Operation failed!",
        severity: "error",
      })
    );
  }
  return next(action);
};

export default toastMiddleware;
