import React from "react";
import Slider from "react-slick";

const storySettings = {
  arrows: true,
  dots: true,
  infinite: false,
  speed: 300,
  slidesToShow: 1,
  centerMode: false,
};

const Sliderone = ({ products }) => {
  return (
    <div className="slider-banner banner-wrap">
      <Slider {...storySettings}>
        {products.map((value, index) => (
          <div key={index}>
            <div
              className="item rounded-6 bg-image-cover ovh style1 d-flex justify-content-center h-100"
              style={{
                backgroundImage: `url(${value.productImages[0]})`,
              }}
            >
              <div
                className="container"
                style={{
                  height: "400px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div className="d-flex justify-content-center slide-content text-center w-75 ps-lg-5">
                  <div className="">
                    <div className="slide-content text-center">
                      <span className="text-grey-700">{value.tag}</span>
                      <h2 className="text-grey-900 text-center">
                        <b>{value.productName}</b>
                      </h2>
                      <p
                        className="text-black fs-20"
                        style={{
                          width: "300px", // Or use maxWidth for flexibility
                          wordWrap: "break-word", // Handle long words by breaking them
                          overflowWrap: "break-word",
                          fontSize: "16px",
                          marginBottom: "50px",
                        }}
                      >
                        {value.description}
                      </p>

                      <div className="clearfix"></div>
                      <a
                        href="/g-1"
                        className="btn-lg rounded-25 btn bg-current mt-40"
                      >
                        SHOP NOW
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Sliderone;
