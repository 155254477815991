import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "./style.css";

const ModalInst = (props) => {
  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" className="">
            complete profile done.
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Thank you for your collaboration. your information will be checked
            by the owners then you can start your work. thank you for your .....
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              props.onHide();
            }}
          >
            Complete Profile
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalInst;
