/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, Fragment, useState } from "react";
import Header from "../components/Header";
import Headermob from "../components/Headermob";
import Upperheader from "../components/Upperheader";
import Lowerheader from "../components/Lowerheader";
import Footer from "../components/Footer";
import Dashnav from "../components/Dashnav";
import Dashmenu from "../components/Dashmenu";
import { useDispatch, useSelector } from "react-redux";
import {
  addNotification,
  getNotificationsAdmin,
  setRead,
} from "../redux/admin";
import socket from "../helper/socketServer";
import VerificationBobUp from "../components/Modal/verificationBobUp";

const Notification = () => {
  const [show, setShow] = useState(false);

  // Redux
  const dispatch = useDispatch();
  const notifications = useSelector((state) => state.admin.notifications);

  useEffect(() => {
    dispatch(getNotificationsAdmin());

    // Listen for new notifications
    socket.on("new-notification", (notification) => {
      dispatch(addNotification(notification));
    });

    return () => {
      socket.off("new-notification");
    };
  }, [dispatch]);

  const formatDate = (value) => {
    if (!value) return "N/A";

    const date = new Date(value);

    return date.toLocaleDateString("en-US", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
  };

  return (
    <Fragment>
      <Headermob />
      <Upperheader />
      <Header />
      <Lowerheader />
      <Dashnav title="Notification" />

      <div className="main-div pb-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <Dashmenu />
            </div>

            <div className="col-lg-8 pt-5 ps-4">
              {notifications.length > 0 ? (
                <ul className="notification-box">
                  {notifications.map((value, index) => (
                    <Fragment key={index}>
                      <VerificationBobUp
                        show={show}
                        setShow={setShow}
                        message={value.message}
                        serviceId={value.from}
                        // eslint-disable-next-line react/jsx-no-comment-textnodes
                      />
                      <li className="mb-1">
                        <a
                          onClick={() => {
                            setShow(true);
                            dispatch(setRead(value._id));
                          }}
                          className={`d-flex align-items-center p-3 rounded-3 ${
                            value.isRead
                              ? "border border-lightblue"
                              : "bg-lightblue theme-light-bg"
                          } cursor-pointer`}
                        >
                          <img
                            src={`assets/images/c.png`}
                            alt="user"
                            className="w-45 me-3 rounded-circle"
                          />
                          <i
                            className={`text-white me-2 font-xssss notification-react feather-bell bg-red-gradiant`}
                          ></i>
                          <h6 className="font-xssss text-grey-900 text-grey-900 mb-0 mt-0 fw-500 lh-20">
                            <strong>{value.fromName}</strong> {value.message}
                            <span className="d-block text-grey-500 font-xssss fw-600 mb-0 mt-0 0l-auto">
                              {formatDate(value.createdAt)}
                            </span>{" "}
                          </h6>
                          <i className="ti-more-alt text-grey-500 font-xs ms-auto"></i>
                        </a>
                      </li>
                    </Fragment>
                  ))}
                </ul>
              ) : (
                <p>No notifications</p>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
};

export default Notification;
