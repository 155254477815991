import axios from "axios";

const api = axios.create({
  baseURL: "https://770d-178-130-149-192.ngrok-free.app/api/v1",
});

// Axios Interceptor to set the appropriate Content-Type
api.interceptors.request.use((config) => {
  if (config.data instanceof FormData) {
    config.headers["Content-Type"] = "multipart/form-data";
  } else {
    config.headers["Content-Type"] = "application/json";
  }
  return config;
});

api.interceptors.request.use(
  (config) => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user && user.token) {
      config.headers.Authorization = `Bearer ${user.token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // Handle errors globally
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.error("Error FROM API INSTANCE:", error.response.data.message);
    } else if (error.request) {
      // The request was made but no response was received
      console.error("Error: No response from server  FROM API INSTANCE");
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error("Error FROM API INSTANCE:", error.message);
    }
    return Promise.reject(error);
  }
);

export default api;
