import React from "react";
import Address from "./Address";

const ServiceProvidersState = {
  arabicName: "",
  idNumber: "",
  companyName: "",
  secondPhoneNo: "",
  companyDescription: "",
  companyDescriptionAr: "",
  businessDetail: "",
  personalImage: "",
};

const RestaurantState = {
  arabicName: "",
  idNumber: "",
  secondPhoneNo: "",
  description: "",
  city: "",
  street: "",
  moreAddressDetail: "",
  personalImage: "",
};

const AddressPage = () => {
  const user = JSON.parse(localStorage.getItem("user")).data;

  return (
    <>
      {user.role === "Restaurant" ? (
        <Address userRole={user.role} initialValues={RestaurantState} />
      ) : (
        <Address userRole={user.role} initialValues={ServiceProvidersState} />
      )}
    </>
  );
};

export default AddressPage;
