import React from "react";
import TextField from "./forms/TextField";
import { Formik } from "formik";
import { categoryValidation } from "../helper/validation";
import { useDispatch } from "react-redux";
import { addCategoryAdmin } from "../redux/category";

const initialCategoryState = {
  name: "",
  nameAr: "",
};

const AddCategory = () => {
  // Redux
  const dispatch = useDispatch();

  const onSubmit = (values, { resetForm }) => {
    dispatch(addCategoryAdmin(values)).then(() => {
      resetForm();
    });
  };

  return (
    <Formik
      validationSchema={categoryValidation}
      initialValues={initialCategoryState}
      onSubmit={onSubmit}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-lg-6 mb-3">
              <div className="form-group">
                <label className="mont-font fw-600 font-xssss mb-2 white-text">
                  Category
                </label>
                <TextField
                  type="text"
                  className="form-control theme-black-bg rounded-10"
                  name="name"
                />
              </div>
            </div>
            <div className="col-lg-6 mb-3">
              <div className="form-group">
                <label className="mont-font fw-600 font-xssss mb-2 white-text">
                  category in Arabic
                </label>
                <TextField
                  type="text"
                  className="form-control theme-black-bg rounded-10"
                  name="nameAr"
                />
              </div>
            </div>
            <div className="col-lg-12">
              <button
                type="submit"
                className="w-100 p-3 bg-current font-xsss ls-1 fw-600 text-white rounded-10 d-block text-center"
              >
                Add
              </button>
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default AddCategory;
