import React, { useRef } from "react";
import "./index.css";

const WrapBackGround = ({ children, setOpen, setMsg, back }) => {
  const modalRef = useRef();

  const closeByRef = (e) => {
    if (modalRef.current === e.target) {
      setOpen(false);
      if (setMsg) {
        setMsg("");
      }
    }
  };

  return (
    <>
      <div
        className="wrapper d-flex justify-content-center align-items-center"
        style={{ backgroundColor: back || "rgba(0, 0, 0, 0.2)" }}
        ref={modalRef}
        onClick={closeByRef}
        back={back}
      >
        {children}
      </div>
    </>
  );
};

export default WrapBackGround;
