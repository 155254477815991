import React, { Fragment, useEffect, useState } from "react";
import Header from "../components/Header";
import Headermob from "../components/Headermob";
import Upperheader from "../components/Upperheader";
import Lowerheader from "../components/Lowerheader";
import Footer from "../components/Footer";
import Dashnav from "../components/Dashnav";
import Dashmenu from "../components/Dashmenu";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllProductsAdmin,
  getProductsUser,
} from "../redux/products/getProducts";
import { Loader } from "../components/Loader";
import ProductsTable from "./Table/productTable";

const Products = () => {
  //state
  const [loader, setLoader] = useState(false);
  const [user, setUser] = useState("");

  // Redux
  const dispatch = useDispatch();
  const products = useSelector((state) => state.getProducts.products);

  // Fetch user data and products
  useEffect(() => {
    const storedUser = JSON.parse(localStorage.getItem("user"))?.data;
    setUser(storedUser);

    setLoader(true);

    if (!storedUser) return; // Stop if no user found

    const fetchProducts = async () => {
      try {
        if (storedUser.role === "Admin") {
          await dispatch(getAllProductsAdmin()).unwrap();
        } else {
          await dispatch(getProductsUser({ userId: user._id })).unwrap();
        }
      } catch (error) {
        console.error("Error fetching products:", error);
      } finally {
        setLoader(false); // Stop loader after fetching
      }
    };

    fetchProducts();
  }, [dispatch, user._id]);

  return (
    <Fragment>
      <Headermob />
      <Upperheader />
      <Header />
      <Lowerheader />
      <Dashnav title="All Products" />

      <div className="main-div pb-5">
        <div className="container" style={{ maxWidth: "1450px" }}>
          <div className="row">
            <div className="col-lg-4" style={{ width: "25%" }}>
              <Dashmenu />
            </div>
            <div className="col-lg-8 pt-5 ps-4" style={{ width: "75%" }}>
              <Loader show={loader} setShow={setLoader} />
              {!loader && products?.length > 0 ? (
                <ProductsTable products={products} />
              ) : (
                !loader && <p>No products available.</p>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
};

export default Products;
